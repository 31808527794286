import React, { useContext, useEffect } from 'react'
import { constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'
import { LookupsContext } from '../../../contexts/LookupsContext'

const {
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const ConveyanceLookupsContext = React.createContext()

const ConveyanceLookupsConsumer = ConveyanceLookupsContext.Consumer

function responseToLookup(
  data,
  valueAccessor = 'code',
  labelAccessor = 'descriptionEn'
) {
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr[valueAccessor]]: curr[labelAccessor] }),
    {}
  )
}

const ConveyanceLookupsProvider = ({ children }) => {
  const { initialize } = useContext(LookupsContext)
  const { fetchRecords } = useServices()

  useEffect(() => {
    const promises = [
      {
        key: 'messageFunction',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'MESSAGE_FUNCTION' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'status',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: CodeMaintenanceType.STATUS },
          transform: (res) => responseToLookup(res.content, 'id')
        }
      },
      {
        key: 'airport',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'AirPortCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'aircraft',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'AircraftTypeCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'statusType',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'SYSTEM_STATUS' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'country',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: CodeMaintenanceType.COUNTRY },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'countryStates',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' },
          transform: (res) => res.content
        }
      }
    ]

    initialize(promises, (response) => {
      const { country, countryStates, ...rest } = response

      const states = Object.keys(country).reduce((acc, countryCode) => {
        acc[countryCode] = countryStates.filter(
          (state) => state.code === countryCode
        )

        return acc
      }, {})

      return { country, states, ...rest }
    })
  }, [])

  return (
    <ConveyanceLookupsContext.Provider>
      {children}
    </ConveyanceLookupsContext.Provider>
  )
}

export default ConveyanceLookupsProvider

export { ConveyanceLookupsContext, ConveyanceLookupsConsumer }
