import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import CngSection from '../../../components/cngcomponents/CngSection'
import FormProperties from '../aciaircommon/AciAirStatusNotifyListFormProperties'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function StatusNotificationSection(props) {
  const { formSettings } = props

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const aciAirStatusNotifyList = watch('aciAirStatusNotifyList')
  const generatedIndexKey = useRef(aciAirStatusNotifyList.length)
  const isMaxAmountReached = aciAirStatusNotifyList.length >= 5
  const maxAmountMessage = 'Only 5 records allowed'

  function makeTranslatedTextsObject() {
    const aciAirStatusNotifyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.TITLE
    )

    return { aciAirStatusNotifyList }
  }

  function handleAddStatus(data) {
    const status = { ...data, _id: generatedIndexKey.current++ }
    const aciAirStatusNotifyList = [...getValues('aciAirStatusNotifyList'), status]

    setValue('aciAirStatusNotifyList', aciAirStatusNotifyList)
  }

  function handleDeleteStatus(index) {
    const aciAirStatusNotifyList = [...getValues('aciAirStatusNotifyList')]

    aciAirStatusNotifyList.splice(index, 1)

    setValue('aciAirStatusNotifyList', aciAirStatusNotifyList)
  }

  return (
    <CngSection
      title={translatedTextsObject.aciAirStatusNotifyList}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        {_.isEmpty(aciAirStatusNotifyList) ? (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
              <span>
                <CngIconButton
                  disabled={isMaxAmountReached}
                  icon={['fal', 'plus']}
                  onClick={() => handleAddStatus(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        ) : (
          aciAirStatusNotifyList.map((status, index) => (
            <React.Fragment key={status.id || status._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md>
                    <FormProperties.Fields index={index} shouldHideMap={{ hideOptionalFields: formSettings.hideOptionalFields }} />
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing={1}>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'plus']}
                              onClick={() => handleAddStatus(initialValues)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Clone'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'copy']}
                              onClick={() => handleAddStatus({ ...status, id: undefined })}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title='Delete'>
                          <span>
                            <CngIconButton
                              icon={['fal', 'trash']}
                              onClick={() => handleDeleteStatus(index)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {aciAirStatusNotifyList.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default React.memo(StatusNotificationSection)
