import React, { useContext, useEffect } from 'react'
import { constants, useServices } from 'cng-web-lib'
import { LookupsContext } from '../../../contexts/LookupsContext'

const {
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const ErrorLogLookupsContext = React.createContext()

const ErrorLogLookupsConsumer = ErrorLogLookupsContext.Consumer

function responseToLookup(
  data,
  valueAccessor = 'code',
  labelAccessor = 'descriptionEn'
) {
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr[valueAccessor]]: curr[labelAccessor] }),
    {}
  )
}

const ErrorLogLookupsProvider = ({ children }) => {
  const { initialize } = useContext(LookupsContext)
  const { fetchRecords } = useServices()

  useEffect(() => {
    const promises = [
      {
        key: 'errorType',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'FEDEX_ERROR_TYPE' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      }
    ]

    initialize(promises)
  }, [])

  return (
    <ErrorLogLookupsContext.Provider>
      {children}
    </ErrorLogLookupsContext.Provider>
  )
}

export default ErrorLogLookupsProvider

export { ErrorLogLookupsContext, ErrorLogLookupsConsumer }
