import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import { generatePath, useParams } from 'react-router-dom'
import Namespace from '../../../constants/locale/Namespace'
import ConveyanceKeys from '../../../constants/locale/key/Conveyance'
import ConveyanceApiUrls from '../../../apiUrls/ConveyanceApiUrls'
import pathMap from '../../../paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import ReportViewContent from './view/ReportViewContent'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, onSetLoading, showNotification } = props

  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [submitDialog, setSubmitDialog] = useState(false)
  const { createRecord, deleteRecord, fetchRecord, securedSendRequest } =
    useServices()
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecord.execute(ConveyanceApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.CONVEYANCE_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  function makeTranslatedTextsObject() {
    const deleteButton = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.DELETE_BUTTON
    )
    const cloneButton = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CLONE_BUTTON
    )
    const editButton = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.EDIT_BUTTON
    )
    const submitButton = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SUBMIT_BUTTON
    )
    const submitRecordDialogContent = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    const submitSuccessMsg = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SUBMIT_SUCCESS_MSG
    )
    const submitErrorMsg = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SUBMIT_ERROR_MSG
    )

    return {
      deleteButton,
      cloneButton,
      editButton,
      submitButton,
      submitRecordDialogContent,
      submitSuccessMsg,
      submitErrorMsg
    }
  }

  if (!data) {
    return <CircularProgress />
  }

  function handleCloneReport() {
    if (history.location.state) {
      createRecord.execute(
        ConveyanceApiUrls.CLONE,
        history.location.state,
        (data) => history.push(pathMap.CONVEYANCE_ADD_VIEW, data),
        (error) => console.error(error)
      )
    }
  }

  function handleDeleteReport() {
    deleteRecord.execute(
      ConveyanceApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.CONVEYANCE_LIST_VIEW)
      },
      (error) => console.error(error)
    )
  }

  function handleSubmitReport() {
    setSubmitDialog(false)

    let report = { ...data }
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      report['fileForUserId'] = fileForUserDetails.fileForUserId
      report['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
      report['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
    }

    onSetLoading(true)

    securedSendRequest.execute(
      'POST',
      ConveyanceApiUrls.SUBMIT,
      report,
      (response) => {
        if (response.status == 200) {
          const { errorMessages } = response.data

          if (!errorMessages || errorMessages.length == 0) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            setData(response.data)
          } else {
            showNotification('error', errorMessages)
          }
        } else showNotification('error', translatedTextsObject.submitErrorMsg)
      },
      () => showNotification('error', translatedTextsObject.submitErrorMsg),
      () => onSetLoading(false)
    )
  }

  function shouldHideSubmitButton(status) {
    return status === 1048 || status === 1
  }

  return (
    <>
      <Card>
        <CardContent>
          <ReportViewContent
            data={data}
            reportId={id}
            showNotification={showNotification}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.CONVEYANCE_LIST_VIEW)}
          >
            Back to Air Cargo Report List
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.hdrStatus === 1005 && (
              <CngButton
                color='secondary'
                onClick={() => setConfirmDialog(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              color='secondary'
              onClick={handleCloneReport}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {(data.hdrStatus !== 1048 || data.hdrStatus !== 1) && (
              <CngButton
                color='primary'
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(
                    generatePath(pathMap.CONVEYANCE_EDIT_VIEW, { id: data.id })
                  )
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
            {!shouldHideSubmitButton(data.hdrStatus) && (
              <CngButton
                color='primary'
                startIcon={
                  <FontAwesomeIcon
                    icon={['fal', 'arrow-alt-right']}
                    size='sm'
                  />
                }
                onClick={() => setSubmitDialog(true)}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteReport}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
      <AlertDialog
        cancelLabel='Close'
        confirmLabel={translatedTextsObject.submitButton}
        open={submitDialog}
        onClose={() => setSubmitDialog(false)}
        onCancel={() => setSubmitDialog(false)}
        onConfirm={handleSubmitReport}
        title={translatedTextsObject.submitButton}
      >
        {translatedTextsObject.submitRecordDialogContent}
      </AlertDialog>
    </>
  )
}

export default withFormState(ViewPage)
