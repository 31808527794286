import React, { useContext, useEffect } from 'react'
import { constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'
import { LookupsContext } from '../../../contexts/LookupsContext'

const {
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const S10LookupsContext = React.createContext()

const S10LookupsConsumer = S10LookupsContext.Consumer

function responseToLookup(
  data,
  valueAccessor = 'code',
  labelAccessor = 'descriptionEn'
) {
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr[valueAccessor]]: curr[labelAccessor] }),
    {}
  )
}

const S10LookupsProvider = ({ children }) => {
  const { initialize } = useContext(LookupsContext)
  const { fetchPageableRecords, fetchRecords } = useServices()

  useEffect(() => {
    const promises = [
      {
        key: 'customsProc',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'AIR_CUSTOMS_PROCEDURE'
            }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'messageFunction',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'MESSAGE_FUNCTION' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'status',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: CodeMaintenanceType.STATUS },
          transform: (res) => responseToLookup(res.content, 'id')
        }
      },
      {
        key: 'supplementaryMatch',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'SUPPLEMENTARY_MATCH' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'riskAssessmentStatus',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'RISK_ASSESSMENT_STATUS'
            }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'uomType',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'uomType', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: CodeMaintenanceType.UOM },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'partyType',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'AIR_PARTY_TYPE' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'grossWeightUom',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'GROSS_WEIGHT_UOM' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'measurementUom',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'VOLUME_UOM' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'customsOffice',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'CustomsOffice' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'sublocation',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'Sublocation' },
          transform: (res) => responseToLookup(res.content, 'intlcode')
        }
      },
      {
        key: 'airport',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'AirPortCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'carrierCode',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'CarrierCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'hsCode',
        request: {
          fetch: fetchPageableRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          page: 0,
          pageSize: 1000,
          sorts: [],
          customData: { codeMType: 'HSCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'undgCode',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'DangerousGoodsCode' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'statusType',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'codeType', operator: EQUAL, value: 'SYSTEM_STATUS' }
          ],
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'country',
        request: {
          fetch: fetchRecords,
          url: CodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: CodeMaintenanceType.COUNTRY },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'countryStates',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' },
          transform: (res) => res.content
        }
      }
    ]

    initialize(promises, (response) => {
      const { country, countryStates, ...rest } = response

      const states = Object.keys(country).reduce((acc, countryCode) => {
        acc[countryCode] = countryStates.filter(
          (state) => state.code === countryCode
        )

        return acc
      }, {})

      return { country, states, ...rest }
    })
  }, [])

  return <S10LookupsContext.Provider>{children}</S10LookupsContext.Provider>
}

export default S10LookupsProvider

export { S10LookupsContext, S10LookupsConsumer }
