import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceKeys from 'src/constants/locale/key/Conveyance'
import { NaAirPortAutocompleteField } from 'src/components/na'
import { NaAircraftTypeAutocompleteField } from 'src/components/na'
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  Switch as MuiSwitch,
  withStyles,
  Paper,
  Popover,
  Divider,
  Checkbox
} from '@material-ui/core'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'
import { matchPath, useLocation } from 'react-router-dom'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from '../../../paths/pathMap'
import RoutesSection from './RoutesSection'
import StatusNotificationsSection from './StatusNotificationsSection'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  messageFun: "",
  msgType: "",
  formType: "187",
  conveyanceRefNo: "",
  carrierCode: "",
  flightNo: "",
  airCraftType: "",
  airCraftRegNO: "",
  departureDateAndTime: "",
  departurePort: "",
  arrivalDateAndTime: "",
  arrivalPort: "",
  arrivalTerminal: "",
  reportType: "794",
  hdrStatus: "",
  scheduleDateAndTime: "",
  scheduledSubmissionFlag: false,
  templateFlag: false,
  templateName: "",
  modeOfSubmission: "UI",
  conveyanceRouteDTOs: [],
  conveyanceStatusNotifyList: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: '8px 16px',
    width: (props) => props.width || theme.breakpoints.values.sm,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        flexGrow: 1
      }
    }
  }
}))(Paper)

function Fields({ disabled, shouldHideMap }) {
  const [formSettings, setFormSettings] = useState({ hideOptionalFields: false })
  const [formSettingsPopover, setFormSettingsPopover] = useState({ anchorEl: null })
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const [
    templateFlag,
    scheduledSubmissionFlag,
    hdrStatus,
  ] = useWatch({
    name: [
      'templateFlag',
      'scheduledSubmissionFlag',
      'hdrStatus',
    ]
  })

  const location = useLocation()

  const isAddPage = location.pathname.toString().indexOf("add") != -1 ? true : false

  const isEditable = !isAddPage && templateFlag && hdrStatus === 1002
  const isConveyanceEditable = !isAddPage && hdrStatus !== 1005

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let conveyance = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.TITLE
    )
    let messageFun = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.MESSAGE_FUNCTION
    )
    let formType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.FORM_TYPE
    )
    let conveyanceRefNo = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CONVEYANCE_REF_NO
    )
    let carrierCode = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CARRIER_CODE
    )
    let flightNo = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.FLIGHT_NO
    )
    let airCraftType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.AIR_CRAFT_TYPE
    )
    let airCraftRegNO = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.AIR_CRAFT_REG_N_O
    )
    let departureDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.DEPARTURE_DATE_AND_TIME
    )
    let departurePort = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.DEPARTURE_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalPort = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_PORT
    )
    let arrivalTerminal = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_TERMINAL
    )
    let reportType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.REPORT_TYPE
    )
    let scheduleDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SCHEDULE_DATE_AND_TIME
    )
    let scheduledSubmissionFlag = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SCHEDULED_SUBMISSION_FLAG
    )
    let conveyanceRouteDTOs = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceRouteDTOs.TITLE
    )
    let locationCode = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceRouteDTOs.LOCATION_CODE
    )
    let conveyanceStatusNotifyList = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.TITLE
    )
    let msgType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.MSG_TYPE
    )
    let name = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.NAME
    )
    let email = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.EMAIL
    )
    let phoneNumber = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.PHONE_NUMBER
    )
    let statusType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.STATUS_TYPE
    )
    let templateFlag = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.TEMPLATE_NAME
    )
    let crhHelpText = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CRN_HELP_TEXT
    )

    return {
      conveyance,
      messageFun,
      formType,
      conveyanceRefNo,
      carrierCode,
      flightNo,
      airCraftType,
      airCraftRegNO,
      departureDateAndTime,
      departurePort,
      arrivalDateAndTime,
      arrivalPort,
      arrivalTerminal,
      reportType,
      scheduleDateAndTime,
      scheduledSubmissionFlag,
      conveyanceRouteDTOs,
      locationCode,
      conveyanceStatusNotifyList,
      templateFlag,
      templateName,
      msgType,
      name,
      email,
      phoneNumber,
      statusType,
      crhHelpText
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h5' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.conveyance}
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Grid container spacing={2}>
                            <Grid item xs='auto'>
                              <CngCheckboxField
                                label={
                                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                    {translatedTextsObject.templateFlag}
                                  </Typography>
                                }
                                name='templateFlag'
                                disabled={disabled || isEditable}
                                onChange={(e) => setValue('templateFlag', e.target.checked)}
                                size='small'
                                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                              />
                            </Grid>
                            <Grid item xs>
                              <CngTextField
                                required
                                name='templateName'
                                inputProps={{ maxLength: 50 }}
                                disabled={!templateFlag || disabled || isEditable}
                                isRequired={templateFlag}
                                onBlur={(e) => setValue('templateName', e.target.value.toUpperCase().trim())}
                                placeholder={translatedTextsObject.templateName}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs='auto'>
                          <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={(event) => setFormSettingsPopover({ anchorEl: event.currentTarget })}
                            size='small'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              width={24}
                              height={24}
                            >
                              <FontAwesomeIcon icon={['fal', 'ellipsis']} />
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.carrierCode}>
                      <CngTextField
                        name='carrierCode'
                        inputProps={{ maxLength: 4 }}
                        label={translatedTextsObject.carrierCode}
                        disabled={disabled}
                        isRequired
                        onBlur={(e) => setValue('carrierCode', e.target.value.toUpperCase())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.conveyanceRefNo}>
                      <CngTextField
                        name='conveyanceRefNo'
                        inputProps={{ maxLength: 16 }}
                        label={translatedTextsObject.conveyanceRefNo}
                        disabled={disabled || isConveyanceEditable}
                        isRequired
                        onBlur={(e) => setValue('conveyanceRefNo', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.flightNo}>
                      <CngTextField
                        name='flightNo'
                        inputProps={{ maxLength: 10 }}
                        label={translatedTextsObject.flightNo}
                        disabled={disabled || isConveyanceEditable}
                        isRequired
                        onBlur={(e) => setValue('flightNo', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.departureDateAndTime}>
                      <CngDateTimeField
                        name='departureDateAndTime'
                        format='YYYY-MM-DD HH:mm'
                        shouldDisableDate={(date) => moment().subtract(1,'days').isAfter(moment(date))}
                        label={translatedTextsObject.departureDateAndTime}
                        isRequired
                        disabled={disabled || isConveyanceEditable}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.arrivalDateAndTime}>
                      <CngDateTimeField
                        name='arrivalDateAndTime'
                        format='YYYY-MM-DD HH:mm'
                        shouldDisableDate={(date) => moment().subtract(1,'days').isAfter(moment(date))}
                        label={translatedTextsObject.arrivalDateAndTime}
                        isRequired
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.airCraftType}>
                      <NaAircraftTypeAutocompleteField
                        name='airCraftType'
                        label={translatedTextsObject.airCraftType}
                        isRequired
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.airCraftRegNO}>
                      <CngTextField
                        name='airCraftRegNO'
                        inputProps={{ maxLength: 12 }}
                        label={translatedTextsObject.airCraftRegNO}
                        isRequired
                        disabled={disabled}
                        onBlur={(e) => setValue('airCraftRegNO', e.target.value.toUpperCase())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.departurePort}>
                      <NaAirPortAutocompleteField
                        name='departurePort'
                        label={translatedTextsObject.departurePort}
                        isRequired
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.arrivalPort}>
                      <NaAirPortAutocompleteField
                        name='arrivalPort'
                        label={translatedTextsObject.arrivalPort}
                        isRequired
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.arrivalTerminal}>
                      <CngTextField
                        name='arrivalTerminal'
                        inputProps={{ maxLength: 30 }}
                        label={translatedTextsObject.arrivalTerminal}
                        disabled={disabled}
                        onBlur={(e) => setValue('airCraftRegNO', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledSubmissionFlag || shouldHideMap.scheduleDateAndTime}>
                      <Card variant='outlined'>
                        <Box padding={1} paddingLeft={2}>
                          <Grid alignItems='center' container spacing={2}>
                            <CngGridItem xs={12} sm={6}>
                              <FormControlLabel
                                control={<Checkbox checked={scheduledSubmissionFlag} />}
                                label={
                                  <Typography
                                    color='textSecondary'
                                    variant='body2'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {translatedTextsObject.scheduledSubmissionFlag}
                                  </Typography>
                                }
                                onChange={(e) => setValue('scheduledSubmissionFlag', e.target.checked)}
                              />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDateAndTime}>
                              <CngDateTimeField
                                name='scheduleDateAndTime'
                                label={translatedTextsObject.scheduleDateAndTime}
                                format='yyyy-MM-DD HH:mm:ss'
                                disabled={disabled || !scheduledSubmissionFlag}
                                isRequired
                                size='small'
                                shouldDisableDate={(date) => moment().isAfter(moment(date))}
                              />
                            </CngGridItem>
                          </Grid>
                        </Box>
                      </Card>
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <RoutesSection />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <StatusNotificationsSection />
        </Grid>
        <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
          <CngCodeMasterAutocompleteField
            name='messageFun'
            label={translatedTextsObject.messageFun}
            disabled={disabled}
            codeType={isAddPage ? 'MESSAGE_FUNCTION_ADD' : 'MESSAGE_FUNCTION'}
            isRequired
          />
        </CngGridItem>
      </Grid>
      <FormSettingsPopover
        anchorEl={formSettingsPopover.anchorEl}
        open={formSettingsPopover.anchorEl ? true : false}
        onClose={() => setFormSettingsPopover({ anchorEl: null })}
        settings={formSettings}
        onChangeSettings={(setting) => setFormSettings((prev) => ({ ...prev, ...setting }))}
      />
    </>
  )
}

function FormSettingsPopover(props) {
  const { anchorEl, open, onClose, settings, onChangeSettings } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverWrapper width={300}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={settings.hideOptionalFields}
              edge='end'
              onChange={(event) =>
                onChangeSettings({ hideOptionalFields: event.target.checked })
              }
            />
          }
          label='Hide optional fields'
          labelPlacement='start'
        />
      </StyledPopoverWrapper>
    </Popover>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.conveyanceRouteDTOs.forEach((route, index) => {
    route['_id'] = index
  })

  localData.scheduleDate = moment(localData.departureDateAndTime).format('YYYY-MM-DD HH:MM');

  localData.arrivalDateAndTime = moment(localData.arrivalDateAndTime).format('YYYY-MM-DD HH:MM');

  return localData
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
