import FlightMatrixApiUrls from 'src/apiUrls/FlightMatrixApiUrls'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import { generatePath, useParams } from 'react-router-dom'
import pathMap from '../../../paths/pathMap'
import Namespace from '../../../constants/locale/Namespace'
import { LookupsContext } from '../../../contexts/LookupsContext'
import FlightMatrixKeys from '../../../constants/locale/key/FlightMatrix'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props

  const { id } = useParams()
  const { getLookupValue } = useContext(LookupsContext)
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { deleteRecord, fetchRecord } = useServices()
  const { translate } = useTranslation(Namespace.FLIGHT_MATRIX)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecord.execute(FlightMatrixApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.FLIGHT_MATRIX_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  function makeTranslatedTextsObject() {
    const flightMatrix = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.TITLE
    )
    const flightNo = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.FLIGHT_NO
    )
    const origin = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.ORIGIN)
    const dest = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.DEST)
    const portOfReport = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.PORT_OF_REPORT
    )
    const wareHouseName = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.WARE_HOUSE_NAME
    )
    const portOfLoading = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.PORT_OF_LOADING
    )
    const deliveryCity = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.DELIVERY_CITY
    )
    const deliveryTerm = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.DELIVERY_TERM
    )
    const customsOffice = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.CUSTOMS_OFFICE
    )
    const customsOfficeTerm = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.CUSTOMS_OFFICE_TERM
    )

    return {
      flightMatrix,
      flightNo,
      origin,
      dest,
      portOfReport,
      wareHouseName,
      portOfLoading,
      deliveryCity,
      deliveryTerm,
      customsOffice,
      customsOfficeTerm
    }
  }

  function handleDeleteMatrix() {
    if (!data) return

    deleteRecord.execute(
      FlightMatrixApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.replace(pathMap.FLIGHT_MATRIX_LIST_VIEW)
      },
      (error) => console.error(error),
      () => setConfirmDialog(false)
    )
  }

  if (!data) {
    return <CircularProgress />
  }

  return (
    <>
      <Card>
        <CardContent>
          <CngSection title={translatedTextsObject.flightMatrix}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.flightNo}>
                  {data.flightNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.origin}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('airport', data.origin)}
                    <TinyChip label={data.origin} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.dest}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('airport', data.dest)}
                    <TinyChip label={data.dest} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portOfReport}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('customsOffice', data.portOfReport)}
                    <TinyChip label={data.portOfReport} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.wareHouseName}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('sublocation', data.wareHouseName)}
                    <TinyChip label={data.wareHouseName} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portOfLoading}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('airport', data.portOfLoading)}
                    <TinyChip label={data.portOfLoading} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.deliveryCity}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('airport', data.deliveryCity)}
                    <TinyChip label={data.deliveryCity} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.deliveryTerm}>
                  {data.deliveryTerm}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.customsOffice}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('customsOffice', data.customsOffice)}
                    <TinyChip label={data.customsOffice} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.customsOfficeTerm}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('sublocation', data.customsOfficeTerm)}
                    <TinyChip label={data.customsOfficeTerm} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
            </Grid>
          </CngSection>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.FLIGHT_MATRIX_LIST_VIEW)}
          >
            Back to Flight Matrix List
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            <CngButton
              color='secondary'
              onClick={() => setConfirmDialog(true)}
              startIcon={<FontAwesomeIcon icon={['fal', 'trash']} size='sm' />}
            >
              Delete
            </CngButton>
            <CngButton
              color='primary'
              startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
              onClick={() =>
                history.push(
                  generatePath(pathMap.FLIGHT_MATRIX_EDIT_VIEW, { id: data.id })
                )
              }
            >
              Edit
            </CngButton>
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteMatrix}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
