import makeValidationSchema from './ConveyanceRouteDTOsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceKeys from 'src/constants/locale/key/Conveyance'
import { NaAirPortAutocompleteField } from 'src/components/na'
import Grid from '@material-ui/core/Grid'

const { CngGridItem } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  locationCode: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number'
      ? `conveyanceRouteDTOs.${index}.${field}`
      : field
  }, [])

  function makeTranslatedTextsObject() {
    let locationCode = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceRouteDTOs.LOCATION_CODE
    )

    return { locationCode }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.locationCode}>
        <NaAirPortAutocompleteField
          name={getFieldName('locationCode')}
          label={translatedTextsObject.locationCode}
          isRequired
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const ConveyanceRouteDTOsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ConveyanceRouteDTOsFormProperties
