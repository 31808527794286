import makeValidationSchema from './ConveyanceStatusNotifyListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceKeys from 'src/constants/locale/key/Conveyance'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  statusType: "",
  email: "",
  phoneNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number'
      ? `conveyanceStatusNotifyList.${index}.${field}`
      : field
  }, [])

  function makeTranslatedTextsObject() {
    const statusType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.STATUS_TYPE
    )
    const email = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.EMAIL
    )
    const phoneNumber = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.PHONE_NUMBER
    )

    return { statusType, email, phoneNumber }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.statusType}>
        <CngCodeMasterAutocompleteField
          name={getFieldName('statusType')}
          inputProps={{ maxLength: 3 }}
          label={translatedTextsObject.statusType}
          isRequired
          disabled={disabled}
          size='small'
          codeType='SYSTEM_STATUS'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name={getFieldName('email')}
          inputProps={{ maxLength: 250 }}
          label={translatedTextsObject.email}
          isRequired
          disabled={disabled}
          size='small'
          onBlur={(e) => setValue('email', e.target.value.toUpperCase().trim())}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.phoneNumber}>
        <CngTextField
          name={getFieldName('phoneNumber')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.phoneNumber}
          disabled={disabled}
          size='small'
          onBlur={(e) => setValue('email', e.target.value.trim())}
        />
      </CngGridItem>
    </Grid>
  )
}

const ConveyanceStatusNotifyListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ConveyanceStatusNotifyListFormProperties
