import { Chip, CircularProgress, Typography, useTheme } from '@material-ui/core'
import { useTranslation, constants, useServices } from 'cng-web-lib'
import AciAirApiUrls from 'src/apiUrls/AciAirAcrApiUrls'
import React, { useContext, useRef, useState } from 'react'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import { generatePath } from 'react-router-dom'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId,FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import pathMap from '../../../paths/pathMap'
import Table from '../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LookupsContext } from '../../../contexts/LookupsContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import _ from 'lodash'

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitDialog, setSubmitDialog] = useState({
    open: false,
    data: []
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    reports: []
  })
  const { getLookupValue } = useContext(LookupsContext)

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const tableRef = useRef()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    let messageFunction = translate(
      Namespace.ACI_AIR,
      AciAirKeys.MESSAGE_FUNCTION
    )
    let customsProc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_PROC
    )
    let voyageNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.VOYAGE_NO
    )
    let portOfLoading = translate(
      Namespace.ACI_AIR,
      AciAirKeys.PORT_OF_LOADING
    )
    let customsOff = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF
    )
    let templateName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.TEMPLATE_NAME
    )
    let ccnPlain = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CCN_PLAIN
    )
    let createdDate = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.ACI_AIR,
      AciAirKeys.UPDATED_DATE
    )
    let sentDate = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SENT_DATE
    )
    let status = translate(
      Namespace.ACI_AIR,
      AciAirKeys.STATUS
    )
    let editSentRecords = translate(
      Namespace.ACI_AIR,
      AciAirKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CLONE_BUTTON
    )
    let cancelButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CANCEL_BUTTON
    )
    let submitButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELETE_BUTTON
    )
    let deleteMultipleRecords = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELETE_MULTIPLE_RECORDS
    )
    let submitSentRecord = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_SENT_RECORD
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_SUCCESS_MSG
    )
    let submitErrorMsg = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_ERROR_MSG
    )
    let submitRecordDialogContent = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    let submitRecord = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_RECORD
    )
    let supplementaryMatch = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUPPLEMENTARY_MATCH
    )
    let riskAssessmentStatus = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RISK_ASSESSMENT_STATUS
    )

    return {
      messageFunction,
      customsProc,
      voyageNo,
      portOfLoading,
      customsOff,
      templateName,
      ccnPlain,
      createdDate,
      updatedDate,
      sentDate,
      status,
      editSentRecords,
      editButton,
      cloneButton,
      cancelButton,
      submitButton,
      deleteButton,
      deleteMultipleRecords,
      submitSentRecord,
      submitSuccessMsg,
      submitErrorMsg,
      submitRecordDialogContent,
      submitRecord,
      supplementaryMatch,
      riskAssessmentStatus
    }
  }

  const columns = [
    {
      field: "ccn",
      sortKey: 'ccn',
      title: [
        translatedTextsObject.ccnPlain,
        translatedTextsObject.customsProc
      ].join(' / '),
      render: (rowData) => (
        <>
          <Typography component='div' variant='inherit'>
            {rowData.ccn}
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {getLookupValue('customsProc', rowData.customsProc)}
          </Typography>
        </>
      )
    },
    {
      field: "customsOff",
      sortKey: 'customsOff',
      title: translatedTextsObject.customsOff,
      render: (rowData) => getLookupValue('customsOffice', rowData.customsOff)
    },
    {
      field: "portOfLoading",
      sortKey: 'portOfLoading',
      title: [
        translatedTextsObject.portOfLoading,
        translatedTextsObject.voyageNo
      ].join(' / '),
      render: (rowData) => (
        <>
          <Typography component='div' variant='inherit'>
            {getLookupValue('airport', rowData.portOfLoading)}
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {rowData.flightNo}
          </Typography>
        </>
      )
    },
    {
      field: 'updatedDate',
      sortKey: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      render: (rowData) =>
        rowData.updatedDate && moment(rowData.updatedDate).format('D MMM YYYY')
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).format('D MMM YYYY')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.sentDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).format('D MMM YYYY')
    },
    {
      field: 'messageFunction',
      sortKey: 'messageFunction',
      title: translatedTextsObject.messageFunction,
      render: (rowData) =>
        getLookupValue('messageFunction', rowData.messageFunction)
    },
    {
      field: "templateName",
      title: translatedTextsObject.templateName,
    },
    {
      field: "hdrStatus",
      sortKey: 'hdrStatus',
      title: translatedTextsObject.status,
      render: (rowData) => {
        const status = getStatusMetadata(rowData.hdrStatus)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    },
    {
      field: "supplementaryMatch",
      sortKey: 'supplementaryMatch',
      title: translatedTextsObject.supplementaryMatch,
      render: (rowData) =>
        getLookupValue('supplementaryMatch', rowData.supplementaryMatch)
    },
    {
      field: "riskAssessmentStatus",
      sortKey: 'riskAssessmentStatus',
      title: translatedTextsObject.riskAssessmentStatus,
      render: (rowData) =>
        getLookupValue('riskAssessmentStatus', rowData.riskAssessmentStatus)
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.ccnPlain,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customsOff,
      type: 'textfield',
      name: 'customsOff',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.portOfLoading,
      type: 'textfield',
      name: 'portOfLoading',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.updatedDate,
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.sentDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneReport(rowData) {
    createRecord.execute(
      AciAirApiUrls.CLONE,
      rowData,
      (data) => history.push(pathMap.ACI_AIR_ACR_ADD_VIEW, data),
      (error) => console.log(error)
    )
  }

  function handleDeleteReports() {
    if (_.isEmpty(confirmDialog.reports)) return

    deleteRecord.execute(
      AciAirApiUrls.DELETE_ALL,
      confirmDialog.reports,
      () => {
        showNotification('success', 'Delete succeeded')

        if (tableRef.current?.performRefresh) {
          tableRef.current.performRefresh()
        }
      },
      (error) => console.error(error),
      () => setConfirmDialog({ open: false, reports: [] })
    )
  }

  function handleSubmitReports() {
    if (_.isEmpty(submitDialog.data)) return

    let reports = [...submitDialog.data]
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      reports.forEach((report) => {
        report['fileForUserId'] = fileForUserDetails.fileForUserId
        report['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
        report['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
      })
    }

    setIsSubmitting(true)

    if (tableRef.current?.setLoading) {
      tableRef.current.setLoading(true)
    }

    securedSendRequest.execute(
      'POST',
      AciAirApiUrls.SUBMIT_ALL,
      reports,
      (response) => {
        if (response.status == 200) {
          const { errorMessages } = response.data

          if (!errorMessages || errorMessages.length == 0) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)

            if (tableRef.current?.performRefresh) {
              tableRef.current.performRefresh()
            }
          } else {
            showNotification('error', errorMessages)
          }
        } else showNotification('error', translatedTextsObject.submitErrorMsg)
      },
      () => showNotification('error', translatedTextsObject.submitErrorMsg),
      () => {
        setIsSubmitting(false)
        setSubmitDialog({ open: false, data: [] })

        if (tableRef.current?.setLoading) {
          tableRef.current.setLoading(false)
        }
      }
    )
  }

  function shouldDisableSubmitButton(rowData) {
    return (
      rowData.hdrStatus === 1048 ||
      rowData.hdrStatus === 1 ||
      rowData.hdrStatus === '1048' ||
      rowData.hdrStatus === '1'
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACI_AIR_ACR_ADD_VIEW)
            },
            label: 'Create ACR Report'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        exportData={{ url: AciAirApiUrls.EXPORT }}
        fetch={{ url: AciAirApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'formType', operator: EQUAL, value: 802 },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          const { hdrStatus, id } = rowData

          const url = generatePath(
            hdrStatus === 1 || hdrStatus === 1048
              ? pathMap.ACI_AIR_ACR_DETAILS_VIEW
              : pathMap.ACI_AIR_ACR_EDIT_VIEW,
            { id }
          )

          history.push(url)
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.hdrStatus === 1048 || rowData.hdrStatus === 1,
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.ACI_AIR_ACR_EDIT_VIEW, { id: rowData.id })
              ),
            tooltip: (rowData) =>
            rowData.hdrStatus === 1048 || rowData.hdrStatus === 1
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneReport
          },
          {
            disabled: (rowData) => rowData.hdrStatus !== 1005,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, reports: [rowData] }),
            tooltip: (rowData) =>
              rowData.hdrStatus !== 1005
                ? translatedTextsObject.deleteMultipleRecords
                : null
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'secondary',
              style: {
                color: theme.palette.error.main,
                minWidth: 'auto'
              }
            },
            disabled: (rows) =>
              isSubmitting ||
              rows.some((rowData) => rowData.hdrStatus !== 1005),
            icon: isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
            ),
            label: translatedTextsObject.deleteButton,
            onClick: (rows) => setConfirmDialog({ open: true, reports: rows }),
            tooltip: (rows) =>
              rows.some((rowData) => rowData.hdrStatus !== 1005)
                ? translatedTextsObject.deleteMultipleRecords
                : null
          },
          {
            buttonProps: {
              style: { minWidth: 'auto' }
            },
            disabled: (rows) =>
              isSubmitting || rows.some(shouldDisableSubmitButton),
            icon: isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
            ),
            label: translatedTextsObject.submitButton,
            onClick: (rows) => setSubmitDialog({ open: true, data: rows }),
            tooltip: (rows) =>
              rows.some(shouldDisableSubmitButton)
                ? translatedTextsObject.submitSentRecord
                : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel={translatedTextsObject.cancelButton}
        confirmLabel={translatedTextsObject.submitButton}
        loading={isSubmitting}
        open={submitDialog.open}
        onClose={() => setSubmitDialog({ open: false, data: [] })}
        onCancel={() => setSubmitDialog({ open: false, data: [] })}
        onConfirm={handleSubmitReports}
        title={translatedTextsObject.submitRecord}
      >
        {translatedTextsObject.submitRecordDialogContent}
      </AlertDialog>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, reports: [] })}
        onCancel={() => setConfirmDialog({ open: false, reports: [] })}
        onConfirm={handleDeleteReports}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
