import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import CngSection from '../../../components/cngcomponents/CngSection'
import FormProperties from '../aciaircommon/AciAirRouteListFormProperties'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function RouteListSection() {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const aciAirRouteList = watch('aciAirRouteList')
  const generatedIndexKey = useRef(aciAirRouteList.length)
  const isMaxAmountReached = aciAirRouteList.length >= 8
  const maxAmountMessage = 'Only 8 records allowed'

  function makeTranslatedTextsObject() {
    const aciAirRouteList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.TITLE
    )

    return { aciAirRouteList }
  }

  function handleAddRoute(data) {
    const route = { ...data, _id: generatedIndexKey.current++ }
    const aciAirRouteList = [...getValues('aciAirRouteList'), route]

    setValue('aciAirRouteList', aciAirRouteList)
  }

  function handleDeleteRoute(index) {
    const aciAirRouteList = [...getValues('aciAirRouteList')]

    aciAirRouteList.splice(index, 1)

    setValue('aciAirRouteList', aciAirRouteList)
  }

  return (
    <CngSection
      title={translatedTextsObject.aciAirRouteList}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        {_.isEmpty(aciAirRouteList) ? (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
              <span>
                <CngIconButton
                  disabled={isMaxAmountReached}
                  icon={['fal', 'plus']}
                  onClick={() => handleAddRoute(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        ) : (
          aciAirRouteList.map((route, index) => (
            <React.Fragment key={route.id || route._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md>
                    <FormProperties.Fields index={index} />
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing={1}>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'plus']}
                              onClick={() => handleAddRoute(initialValues)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Clone'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'copy']}
                              onClick={() => handleAddRoute({ ...route, id: undefined })}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title='Delete'>
                          <span>
                            <CngIconButton
                              icon={['fal', 'trash']}
                              onClick={() => handleDeleteRoute(index)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {aciAirRouteList.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default React.memo(RouteListSection)
