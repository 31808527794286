import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import _ from 'lodash'

import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  Switch as MuiSwitch,
  withStyles,
  Paper,
  Popover,
  Divider,
  Checkbox
} from '@material-ui/core'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { NaCustomsOfficeAutocompleteField, NaSublocationAutocompleteField, NaAirPortAutocompleteField } from 'src/components/na'
import moment from 'moment'
import { matchPath } from "react-router"
import { useLocation } from 'react-router-dom'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon';
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from '../../../paths/pathMap'
import ManifestQuantityListSection from './ManifestQuantityListSection'
import EquipmentListSection from './EquipmentListSection'
import RouteListSection from './RouteListSection'
import PartyListSection from './PartyListSection'
import ItemSection from './ItemSection'
import StatusNotificationSection from './StatusNotificationSection'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateTimeField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  messageFunction: "",
  id: "",
  msgType: "",
  formType: "802",
  customsProc: "",
  convRefNo: "",
  flightNo: "",
  mot: "4",
  carrierCode: "",
  ccn: "",
  portOfLoading: "",
  receiptCountry: "",
  receiptCity: "",
  deliveryCountry: "",
  deliveryCity: "",
  customsOff: "",
  warehouseDischarge: "",
  splitShipmentInd: "0",
  rampTransferInd: "0",
  suppReqInd: "0",
  specialIns: "",
  scheduleDateAndTime: "",
  scheduledSubmissionFlag: false,
  templateFlag: false,
  templateName: "",
  hdrStatus: "",
  uniqueConsignmentRefNo: "",
  receiptTerminalName: "",
  cargoFacilityLoc: "",
  customsOfficeDischarge: "",
  customsOfficeDischargeLoc: "",
  modeOfSubmission: "UI",
  aciAirRouteList: [],
  aciAirPartyList: [],
  aciAirManifestQtyList: [],
  aciAirEquipmentList: [],
  aciAirItemList: [],
  aciAirStatusNotifyList: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: '8px 16px',
    width: (props) => props.width || theme.breakpoints.values.sm,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        flexGrow: 1
      }
    }
  }
}))(Paper)

function Fields({ disabled, shouldHideMap }) {
  const [formSettings, setFormSettings] = useState({ hideOptionalFields: false })
  const [formSettingsPopover, setFormSettingsPopover] = useState({ anchorEl: null })
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const [
    templateFlag,
    ccn,
    scheduledSubmissionFlag,
    hdrStatus,
    customsProc,
    splitShipmentInd
  ] = useWatch({
    name: [
      'templateFlag',
      'ccn',
      'scheduledSubmissionFlag',
      'hdrStatus',
      'customsProc',
      'splitShipmentInd'
    ]
  })

  const location = useLocation()

  const editPage = !!matchPath(location.pathname, { path: pathMap.ACI_AIR_ACR_EDIT_VIEW })
  const addPage = !!matchPath(location.pathname, { path: pathMap.ACI_AIR_ACR_ADD_VIEW })

  const isEditable = editPage && templateFlag && hdrStatus === 1002

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let header = translate(
      Namespace.ACI_AIR,
      AciAirKeys.HEADER
    )
    let schedule = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE
    )
    let messageFunction = translate(
      Namespace.ACI_AIR,
      AciAirKeys.MESSAGE_FUNCTION
    )
    let customsProc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_PROC
    )
    let convRefNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CONV_REF_NO
    )
    let flightNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.VOYAGE_NO
    )
    let carrierCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CCN
    )
    let portOfLoading = translate(
      Namespace.ACI_AIR,
      AciAirKeys.PORT_OF_LOADING
    )
    let receiptCountry = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RECEIPT_COUNTRY
    )
    let receiptCity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RECEIPT_CITY
    )
    let deliveryCountry = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_COUNTRY
    )
    let deliveryCity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_CITY
    )
    let customsOff = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF
    )
    let warehouseDischarge = translate(
      Namespace.ACI_AIR,
      AciAirKeys.WAREHOUSE_DISCHARGE
    )
    let splitShipmentInd = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SPLIT_SHIPMENT_IND
    )
    let rampTransferInd = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RAMP_TRANSFER_IND
    )
    let suppReqInd = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUPP_REQ_IND
    )
    let specialIns = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SPECIAL_INS
    )
    let scheduleDateAndTime = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_DATE_TIME
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_SUBMISSION_FLAG
    )
    let templateFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.TEMPLATE_NAME
    )
    let uniqueConsignmentRefNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.UNIQUE_CONSIGNMENT_REF_NO
    )
    let receiptTerminalName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RECEIPT_TERMINAL_NAME
    )
    let cargoFacilityLoc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CARGO_FACILITY_LOC
    )
    let customsOfficeDischarge = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF_DISCHARGE
    )
    let customsOfficeDischargeLoc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF_DISCHARGE_LOC
    )
    let crhHelpText = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CRN_HELP_TEXT
    )

    return {
      header,
      schedule,
      messageFunction,
      customsProc,
      convRefNo,
      flightNo,
      carrierCode,
      ccn,
      portOfLoading,
      receiptCountry,
      receiptCity,
      deliveryCountry,
      deliveryCity,
      customsOff,
      warehouseDischarge,
      splitShipmentInd,
      rampTransferInd,
      suppReqInd,
      specialIns,
      scheduleDateAndTime,
      scheduledSubmissionFlag,
      templateFlag,
      templateName,
      uniqueConsignmentRefNo,
      receiptTerminalName,
      cargoFacilityLoc,
      customsOfficeDischarge,
      customsOfficeDischargeLoc,
      crhHelpText
    }
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h5' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.header}
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Grid container spacing={2}>
                            <Grid item xs='auto'>
                              <CngCheckboxField
                                label={
                                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                    {translatedTextsObject.templateFlag}
                                  </Typography>
                                }
                                name='templateFlag'
                                disabled={disabled || isEditable}
                                onChange={(e) => setValue('templateFlag', e.target.checked)}
                                size='small'
                                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                              />
                            </Grid>
                            <Grid item xs>
                              <CngTextField
                                required
                                name='templateName'
                                inputProps={{ maxLength: 50 }}
                                disabled={!templateFlag || disabled || isEditable}
                                isRequired={templateFlag}
                                onBlur={(e) => setValue('templateName', e.target.value.toUpperCase().trim())}
                                placeholder={translatedTextsObject.templateName}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs='auto'>
                          <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={(event) => setFormSettingsPopover({ anchorEl: event.currentTarget })}
                            size='small'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              width={24}
                              height={24}
                            >
                              <FontAwesomeIcon icon={['fal', 'ellipsis']} />
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.carrierCode}>
                      <CngTextField
                        name='carrierCode'
                        inputProps={{ maxLength: 4 }}
                        label={translatedTextsObject.carrierCode}
                        disabled={disabled}
                        isRequired
                        onBlur={(e) => {
                          const value = e.target.value.toUpperCase()

                          setValue('carrierCode', value)

                          if (ccn === '' || ccn.length <= 4) {
                            setValue('ccn', value)
                          }
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.ccn}>
                      <CngTextField
                        name='ccn'
                        inputProps={{ maxLength: 25 }}
                        label={translatedTextsObject.ccn}
                        disabled={disabled || (editPage && hdrStatus !== 1005)}
                        isRequired
                        onBlur={(e) => setValue('ccn', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.customsProc}>
                      <CngCodeMasterAutocompleteField
                        name='customsProc'
                        label={translatedTextsObject.customsProc}
                        disabled={disabled}
                        codeType='AIR_CUSTOMS_PROCEDURE'
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.splitShipmentInd || formSettings.hideOptionalFields}>
                      <CngCodeMasterAutocompleteField
                        name='splitShipmentInd'
                        label={translatedTextsObject.splitShipmentInd}
                        disabled={disabled}
                        codeType='YES_OR_NO'
                        onChange={() => {
                          setValue('convRefNo', '', { shouldValidate: true })
                          setValue('flightNo', '', { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.rampTransferInd || formSettings.hideOptionalFields}>
                      <CngCodeMasterAutocompleteField
                        name='rampTransferInd'
                        label={translatedTextsObject.rampTransferInd}
                        disabled={disabled}
                        codeType='YES_OR_NO'
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.suppReqInd || formSettings.hideOptionalFields}>
                      <CngCodeMasterAutocompleteField
                        name='suppReqInd'
                        label={translatedTextsObject.suppReqInd}
                        disabled={disabled}
                        codeType='YES_OR_NO'
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.uniqueConsignmentRefNo || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='uniqueConsignmentRefNo'
                        inputProps={{ maxLength: 35 }}
                        label={translatedTextsObject.uniqueConsignmentRefNo}
                        disabled={disabled}
                        onBlur={(e) => setValue('uniqueConsignmentRefNo', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.specialIns || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='specialIns'
                        inputProps={{ maxLength: 60 }}
                        label={translatedTextsObject.specialIns}
                        disabled={disabled}
                        onBlur={(e) => setValue('specialIns', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ManifestQuantityListSection />
                </Grid>
                <Grid item xs={12}>
                  <EquipmentListSection />
                </Grid>
                <Grid item xs={12}>
                  <CngSection
                    title={translatedTextsObject.schedule}
                    subheader={
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                      </Typography>
                    }
                  >
                    <Grid container spacing={1}>
                      {splitShipmentInd === '0' && (
                        <>
                          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.convRefNo}>
                            <CngTextField
                              name='convRefNo'
                              inputProps={{ maxLength: 16 }}
                              label={translatedTextsObject.convRefNo}
                              helperText={translatedTextsObject.crhHelpText}
                              disabled={disabled}
                              isRequired
                              onBlur={(e) => {
                                const value = e.target.value.toUpperCase()

                                setValue('convRefNo', value)

                                const conveyanceRefNo = value.trim()
                                const flightNoValue = conveyanceRefNo.substring(0, conveyanceRefNo.length - 6)

                                setValue('flightNo', flightNoValue, { shouldValidate: true })
                              }}
                              size='small'
                            />
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.flightNo}>
                            <CngTextField
                              name='flightNo'
                              inputProps={{ maxLength: 10 }}
                              label={translatedTextsObject.flightNo}
                              disabled={disabled}
                              isRequired
                              onBlur={(e) => setValue('flightNo', e.target.value.toUpperCase().trim())}
                              size='small'
                            />
                          </CngGridItem>
                        </>
                      )}
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.receiptCountry}>
                        <CngCountryAutocompleteField
                          name='receiptCountry'
                          label={translatedTextsObject.receiptCountry}
                          disabled={disabled}
                          isRequired
                          searchableFields={['data.code', 'data.descriptionEn']}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.receiptCity}>
                        <CngTextField
                          name='receiptCity'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.receiptCity}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('receiptCity', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.receiptTerminalName}>
                        <CngTextField
                          name='receiptTerminalName'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.receiptTerminalName}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('receiptTerminalName', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.deliveryCountry}>
                        <CngCountryAutocompleteField
                          name='deliveryCountry'
                          label={translatedTextsObject.deliveryCountry}
                          disabled={disabled}
                          isRequired
                          searchableFields={['data.code', 'data.descriptionEn']}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.deliveryCity}>
                        <CngTextField
                          name='deliveryCity'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.deliveryCity}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('deliveryCity', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.cargoFacilityLoc}>
                        <CngTextField
                          name='cargoFacilityLoc'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.cargoFacilityLoc}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('cargoFacilityLoc', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      {(customsProc === '23' || customsProc === '24') && (
                        <>
                          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.customsOfficeDischarge}>
                            <NaCustomsOfficeAutocompleteField
                              name='customsOfficeDischarge'
                              label={translatedTextsObject.customsOfficeDischarge}
                              disabled={disabled}
                              isRequired
                              size='small'
                            />
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.customsOfficeDischargeLoc}>
                            <NaSublocationAutocompleteField
                              name='customsOfficeDischargeLoc'
                              label={translatedTextsObject.customsOfficeDischargeLoc}
                              disabled={disabled}
                              isRequired
                              size='small'
                            />
                          </CngGridItem>
                        </>
                      )}
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.portOfLoading}>
                        <NaAirPortAutocompleteField
                          name='portOfLoading'
                          label={translatedTextsObject.portOfLoading}
                          disabled={disabled}
                          isRequired
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.customsOff}>
                        <NaCustomsOfficeAutocompleteField
                          name='customsOff'
                          label={translatedTextsObject.customsOff}
                          disabled={disabled}
                          isRequired
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.warehouseDischarge || formSettings.hideOptionalFields}>
                        <NaSublocationAutocompleteField
                          name='warehouseDischarge'
                          label={translatedTextsObject.warehouseDischarge}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledSubmissionFlag || shouldHideMap.scheduleDateAndTime}>
                        <Card variant='outlined'>
                          <Box padding={1} paddingLeft={2}>
                            <Grid alignItems='center' container spacing={2}>
                              <CngGridItem xs={12} sm={6}>
                                <FormControlLabel
                                  control={<Checkbox checked={scheduledSubmissionFlag} />}
                                  label={
                                    <Typography
                                      color='textSecondary'
                                      variant='body2'
                                      style={{ fontWeight: 600 }}
                                    >
                                      {translatedTextsObject.scheduledSubmissionFlag}
                                    </Typography>
                                  }
                                  onChange={(e) => setValue('scheduledSubmissionFlag', e.target.checked)}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDateAndTime}>
                                <CngDateTimeField
                                  name='scheduleDateAndTime'
                                  label={translatedTextsObject.scheduleDateAndTime}
                                  format='yyyy-MM-DD HH:mm:ss'
                                  disabled={disabled || !scheduledSubmissionFlag}
                                  isRequired
                                  size='small'
                                  shouldDisableDate={(date) => moment().isAfter(moment(date))}
                                />
                              </CngGridItem>
                            </Grid>
                          </Box>
                        </Card>
                      </CngGridItem>
                    </Grid>
                  </CngSection>
                </Grid>
                <Grid item xs={12}>
                  <RouteListSection />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PartyListSection formSettings={formSettings} />
        </Grid>
        <Grid item xs={12}>
          <ItemSection formSettings={formSettings} />
        </Grid>
        <Grid item xs={12}>
          <StatusNotificationSection formSettings={formSettings} />
        </Grid>
        <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
          {editPage && (
            <CngCodeMasterAutocompleteField
              name='messageFunction'
              label={translatedTextsObject.messageFunction}
              disabled={disabled}
              codeType='MESSAGE_FUNCTION'
              isRequired
            />
          )}
          {addPage && (
            <CngCodeMasterAutocompleteField
              name='messageFunction'
              label={translatedTextsObject.messageFunction}
              disabled={disabled}
              codeType='MESSAGE_FUNCTION_ADD'
              isRequired
            />
          )}
        </CngGridItem>
      </Grid>
      <FormSettingsPopover
        anchorEl={formSettingsPopover.anchorEl}
        open={formSettingsPopover.anchorEl ? true : false}
        onClose={() => setFormSettingsPopover({ anchorEl: null })}
        settings={formSettings}
        onChangeSettings={(setting) => setFormSettings((prev) => ({ ...prev, ...setting }))}
      />
    </>
	)
}

function FormSettingsPopover(props) {
  const { anchorEl, open, onClose, settings, onChangeSettings } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverWrapper width={300}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={settings.hideOptionalFields}
              edge='end'
              onChange={(event) =>
                onChangeSettings({ hideOptionalFields: event.target.checked })
              }
            />
          }
          label='Hide optional fields'
          labelPlacement='start'
        />
      </StyledPopoverWrapper>
    </Popover>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.aciAirManifestQtyList.forEach((manifestQuantity, index) => {
    manifestQuantity['_id'] = index
  })

  localData.aciAirEquipmentList.forEach((equipment, index) => {
    equipment['_id'] = index
  })

  //to convert from backend to List<String> locationCode from String:locationCode
  const transformedData = {};
  localData.aciAirRouteList.forEach((route, index) => {
    route['_id'] = index

    if (!transformedData[route.carrierCode]) {
      transformedData[route.carrierCode] = [];
    }
    transformedData[route.carrierCode].push(route.locationCode);
  });
  for (const key in transformedData) {
  transformedData[key] = [...new Set(transformedData[key])];
  }
  const result = Object.entries(transformedData).map(([carrierCode, locationCode]) => ({
  carrierCode,
  locationCode
  }));

  localData.aciAirRouteList=result;

  localData.aciAirPartyList.forEach((party, index) => {
    party['_id'] = index
    party['saveFlag'] = !!party.saveFlag
  })

  localData.aciAirItemList.forEach((item, index) => {
    item['_id'] = index
    item['saveFlag'] = !!item.saveFlag
  })

  localData.aciAirStatusNotifyList.forEach((status, index) => {
    status['_id'] = index
  })
  
  return localData
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }
  let newRouteList=[];

  //to send to server as String:locationCode instead of List<String> locationCode
  if (!_.isEmpty(serverData.aciAirRouteList)) {
    serverData.aciAirRouteList.forEach((route) => {
      let cCode=route.carrierCode;
      route.locationCode.forEach((locationString) => {
        let routeObject={
          carrierCode: cCode,
          locationCode: locationString,
        }
        newRouteList.push(routeObject);
      })
    })
  }
  serverData.aciAirRouteList=newRouteList;
  return serverData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
