import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import CngSection from '../../../components/cngcomponents/CngSection'
import FormProperties from '../aciaircommon/AciAirEquipmentListFormProperties'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function EquipmentListSection() {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const aciAirEquipmentList = watch('aciAirEquipmentList')
  const generatedIndexKey = useRef(aciAirEquipmentList.length)
  const isMaxAmountReached = aciAirEquipmentList.length >= 999
  const maxAmountMessage = 'Only 999 records allowed'

  function makeTranslatedTextsObject() {
    const aciAirEquipmentList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirEquipmentList.TITLE
    )

    return { aciAirEquipmentList }
  }

  function handleAddEquipment(data) {
    const equipment = { ...data, _id: generatedIndexKey.current++ }
    const aciAirEquipmentList = [...getValues('aciAirEquipmentList'), equipment]

    setValue('aciAirEquipmentList', aciAirEquipmentList)
  }

  function handleDeleteEquipment(index) {
    const aciAirEquipmentList = [...getValues('aciAirEquipmentList')]

    aciAirEquipmentList.splice(index, 1)

    setValue('aciAirEquipmentList', aciAirEquipmentList)
  }

  return (
    <CngSection
      title={translatedTextsObject.aciAirEquipmentList}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        {_.isEmpty(aciAirEquipmentList) ? (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
              <span>
                <CngIconButton
                  disabled={isMaxAmountReached}
                  icon={['fal', 'plus']}
                  onClick={() => handleAddEquipment(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        ) : (
          aciAirEquipmentList.map((equipment, index) => (
            <React.Fragment key={equipment.id || equipment._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md>
                    <FormProperties.Fields index={index} />
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing={1}>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'plus']}
                              onClick={() => handleAddEquipment(initialValues)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Clone'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'copy']}
                              onClick={() => handleAddEquipment({ ...equipment, id: undefined })}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title='Delete'>
                          <span>
                            <CngIconButton
                              icon={['fal', 'trash']}
                              onClick={() => handleDeleteEquipment(index)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {aciAirEquipmentList.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default React.memo(EquipmentListSection)
