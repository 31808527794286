import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return Yup.object({
    formType: Yup.string().max(3).required(requiredMessage).nullable(),
    customsProc: Yup.string().max(2).required(requiredMessage).nullable(),
    convRefNo: Yup.string()
      .trim()
      .when('splitShipmentInd', {
        is: '0',
        then: Yup.lazy((value) =>
          value === ''
            ? Yup.string()
            : Yup.string()
                .trim()
                .matches(
                  '[a-zA-Z0-9]$',
                  'Only alphanumeric values are allowed.'
                )
                .min(7, 'Min length needed is 7')
                .max(16, 'Max length allowed is 16.')
                .nullable()
                .test(
                  'Check Flight Number',
                  'Conveyance Reference Number should be prefixed with Flight Number',
                  function () {
                    let flightNo = this.parent['flightNo']
                    let convRefNo = this.parent['convRefNo']
                    if (flightNo && convRefNo) {
                      if (convRefNo.startsWith(flightNo)) {
                        return true
                      } else {
                        document.getElementById(
                          'convRefNo-helper-text'
                        ).innerHTML =
                          'Conveyance Reference Number should be prefixed with Flight Number.'
                        return false
                      }
                    } else {
                      return true
                    }
                  }
                )
                .test(
                  'Check Departure Date',
                  'Departure Date should be in YYMMDD format.',
                  function () {
                    let convRefNo = this.parent['convRefNo']
                    if (convRefNo !== undefined && convRefNo !== null) {
                      let departureDate = convRefNo.substring(
                        convRefNo.length,
                        convRefNo.length - 6
                      )
                      if (convRefNo && convRefNo.length > 6) {
                        if (moment(departureDate, 'YYMMDD', true).isValid()) {
                          document.getElementById(
                            'convRefNo-helper-text'
                          ).innerHTML =
                            'CRN = Flight Number + Departure Date (YYMMDD)'
                          return true
                        } else {
                          document.getElementById(
                            'convRefNo-helper-text'
                          ).innerHTML =
                            'Last 6 characters is Departure Date and should be in YYMMDD format.'
                          return false
                        }
                      } else {
                        document.getElementById(
                          'convRefNo-helper-text'
                        ).innerHTML =
                          'Conveyance Reference Number should be Flight Number followed by Departure Date (YYMMDD).'
                        return false
                      }
                    } else {
                      document.getElementById(
                        'convRefNo-helper-text'
                      ).innerHTML =
                        'CRN = Flight Number + Departure Date (YYMMDD)'
                      return true
                    }
                  }
                )
        )
      })
      .nullable(),
    flightNo: Yup.string()
      .trim()
      .when('splitShipmentInd', {
        is: (value) => value === '0',
        then: Yup.lazy((value) =>
          value === ''
            ? Yup.string()
            : Yup.string()
                .trim()
                .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed')
                .min(1, 'Min length needed is 1')
                .max(10, 'Max length allowed is 10.')
                .nullable()
        )
      })
      .nullable(),
    mot: Yup.string().required(requiredMessage).nullable(),
    carrierCode: Yup.string()
      .required(requiredMessage)
      .min(4, 'Should be a valid 4 character Carrier Code')
      .max(4, 'Should be a valid 4 character Carrier Code')
      .matches(
        '^[a-zA-Z0-9-]{4}$',
        'Carrier Code must be 4 characters long and cannot contain special characters.'
      ),
    ccn: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(5, 'Min length needed is 5.')
      .max(25, 'Max length allowed is 25.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed'),
    portOfLoading: Yup.string()
      .required(requiredMessage)
      .min(3, 'Min length needed is 3.')
      .max(4, 'Max length allowed is 4.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed.')
      .nullable(),
    receiptCountry: Yup.string()
      .required(requiredMessage)
      .min(2, 'Min length needed is 2.')
      .nullable(),
    receiptCity: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(25, 'Max length allowed is 25.')
      .matches('[a-zA-Zs]$', 'Only alphabets are allowed.'),
    deliveryCountry: Yup.string()
      .required(requiredMessage)
      .min(2, 'Min length needed is 2.')
      .nullable(),
    deliveryCity: Yup.string()
      .trim()
      .max(25, 'Max length allowed is 25.')
      .required(requiredMessage)
      .matches('[a-zA-Zs]$', 'Only alphabets are allowed.'),
    customsOff: Yup.string()
      .required(requiredMessage)
      .min(4, 'Min length needed is 4.')
      .max(4, 'Max length allowed is 4.')
      .matches('[0-9]+', 'Only numbers are allowed.')
      .nullable(),
    warehouseDischarge: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .nullable()
            .matches(
              '[a-zA-Z0-9s]$',
              'Only alphanumeric and spaces are allowed.'
            )
    ),
    splitShipmentInd: Yup.string().required(requiredMessage).nullable(),
    rampTransferInd: Yup.string().nullable(),
    suppReqInd: Yup.string().nullable(),
    specialIns: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.')
      .matches(
        '[a-zA-Z0-9-.s]*$',
        'Special Instructions cannot contain special characters.'
      )
      .nullable(),
    uniqueConsignmentRefNo: Yup.string()
      .trim()
      .max(35, 'Max length allowed is 35.')
      .matches(
        '^[a-zA-Z0-9]*$',
        'Consignment Reference No cannot contain special characters.'
      )
      .nullable(),
    receiptTerminalName: Yup.string()
      .trim()
      .max(25, 'Max length allowed is 25.')
      .required(requiredMessage)
      .matches('[a-zA-Z0-9s]$', 'Only alphanumeric are allowed.')
      .nullable(),
    cargoFacilityLoc: Yup.string()
      .trim()
      .max(25, 'Max length allowed is 25.')
      .required(requiredMessage)
      .matches('[a-zA-Z0-9s]$', 'Only alphanumeric are allowed.')
      .nullable(),
    customsOfficeDischarge: Yup.string()
      .when('customsProc', {
        is: '23',
        then: Yup.string()
          .required(requiredMessage)
          .max(4, 'Max length allowed is 4.')
          .matches('^[0-9]+$', 'Only numbers are allowed.')
      })
      .when('customsProc', {
        is: '24',
        then: Yup.string()
          .required(requiredMessage)
          .max(4, 'Max length allowed is 4.')
          .matches('^[0-9]+$', 'Only numbers are allowed.')
      })
      .nullable(),
    customsOfficeDischargeLoc: Yup.string()
      .max(4, 'Max length allowed is 4.')
      .matches('^[0-9]+$', 'Only numbers are allowed.')
      .nullable(),
    scheduledSubmissionFlag: Yup.boolean().required(requiredMessage),
    scheduleDateAndTime: Yup.string()
      .when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string()
          .required('Please select a valid date.')
          .test(
            'scheduleDateAndTime',
            'Please select a valid Future Scheduled Date Time in yyyy-mm-dd HH:mm:ss format.',
            (value) => {
              var formattedDate = moment(value).format('YYYY-MM-DD')
              var formattedToday = moment(today).format('YYYY-MM-DD')
              return moment(formattedDate).isAfter(formattedToday, 'days')
            }
          )
      })
      .nullable(),
    templateFlag: Yup.boolean().required(requiredMessage),
    templateName: Yup.string()
      .when('templateFlag', {
        is: true,
        then: Yup.string()
          .trim()
          .max(1, 'Max length allowed is 1')
          .max(50, 'Max length is 50')
          .required(requiredMessage)
          .matches('[a-zA-Z0-9s]$', 'Only alphanumeric characters are allowed.')
      })
      .nullable(),
    hdrStatus: Yup.string().nullable(),
    messageFunction: 
    Yup.string()
      .when('hdrStatus', {
        is: (value) => value === '1000',
        then: Yup.string().test(
          'messageFunction',
          'Confirmed record cannot be submitted as Original.',
          function (value) {
            return value !== "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '20' || value === '21' || value === '28'),
        then: Yup.string().test(
          'messageFunction',
          'Error status record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '29'),
        then: Yup.string().test(
          'messageFunction',
          'Error status record should be submitted as Original / Change.',
          function (value) {
            return (value == "9" || value == "4")
          }
        )
      })      
      .when('hdrStatus', {
        is: (value) => (value === '22'),
        then: Yup.string().test(
          'messageFunction',
          'Conformance/Syntax Error status record should be submitted as Change.',
          function (value) {
            return value === "4"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '1003' || value === '1004'),
        then: Yup.string().test(
          'messageFunction',
          'Cancel Accepted/Rejected record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .nullable()
      .required(requiredMessage),
    aciAirRouteList: Yup.array(),
    aciAirPartyList: Yup.array(Yup.object({
      saveFlag: Yup.boolean().required(requiredMessage),
      partyIdentification: Yup.string()
        .when('saveFlag', {
          is: false,
          then: (schema) => schema,
          otherwise: Yup.string().when('saveFlag', {
            is: true,
            then: Yup.string()
              .trim()
              .min(1, 'Max length allowed is 1.')
              .max(35, 'Max length is 35.')
              .required(requiredMessage)
              .matches(
                '[a-zA-Z0-9s]$',
                'Only alphanumeric characters are allowed.'
              )
          })
        })
        .nullable(),
      partyType: Yup.string()
        .required(requiredMessage)
        .min(2, 'Min length needed is 2.')
        .max(2, 'Max length allowed is 2.')
        .nullable(),
      partyName1: Yup.string()
        .trim()
        .max(35, 'Max length allowed is 35.')
        .required(requiredMessage)
        .matches('[a-zA-Z0-9s.,]$', 'Only alphanumeric values are allowed.'),
      partyName2: Yup.lazy((value) =>
        value === ''
          ? Yup.string()
          : Yup.string()
              .trim()
              .max(35, 'Max length allowed is 35.')
              .matches('[a-zA-Z0-9s.,]$', 'Only alphanumeric values are allowed.')
              .nullable()
      ),
      address1: Yup.string()
        .trim()
        .max(35, 'Max length allowed is 35.')
        .required(requiredMessage)
        .matches(
          /^[a-zA-Z0-9\s-.,]+$/,
          'Only alphanumeric, space and dashes are allowed.'
        ),
      address2: Yup.lazy((value) =>
        value === ''
          ? Yup.string()
          : Yup.string()
              .trim()
              .max(35, 'Max length allowed is 35.')
              .matches(
                /^[a-zA-Z0-9\s-.,]+$/,
                'Only alphanumeric, space and dashes are allowed.'
              )
              .nullable()
      ),
      city: Yup.string()
        .trim()
        .max(35, 'Max length allowed is 35.')
        .required(requiredMessage)
        .matches('[a-zA-Z0-9s]$', 'Only alphanumeric values are allowed.'),
      countryCode: Yup.string()
        .max(2, 'Max length allowed is 2.')
        .required(requiredMessage)
        .nullable(),
      postalCode: Yup.string()
        .when('countryCode', {
          is: 'CA',
          then: Yup.string()
            .trim()
            .max(9, 'Max length allowed is 9.')
            .required('Please enter a value for Postal Code.')
            .matches(
              /^[a-zA-Z]\d[a-zA-Z][ ]?\d[a-zA-Z]\d$/i,
              'Please enter Postal Code in proper format. (e.g. A1B 1C1)'
            ),
          otherwise: Yup.string().when('countryCode', {
            is: 'US',
            then: Yup.string()
              .trim()
              .max(9, 'Max length allowed is 9.')
              .required('Please enter a value for Postal Code.')
              .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed.')
          })
        })
        .nullable(),
      stateCode: Yup.string()
        .when('countryCode', {
          is: (countryCode) => countryCode === 'US' || countryCode === 'CA',
          then: Yup.lazy((value) =>
            value === ''
              ? Yup.string()
                  .trim()
                  .required('Please select a value for Province/State Code.')
              : Yup.string()
          ),
          otherwise: Yup.string().when('countryCode', {
            is: (countryCode) => countryCode !== 'US' || countryCode !== 'CA',
            then: Yup.lazy((value) =>
              value === ''
                ? Yup.string()
                : Yup.string()
                    .trim()
                    .nullable()
                    .matches('[a-zA-Z]$', 'Only alpha values are allowed.')
            )
          })
        })
        .nullable(),
      contactPerson: Yup.string().matches("^[a-zA-Z0-9 -]*$", 'Only alpha values are allowed.').max(35, 'Max length allowed is 35.').nullable(),
      telNo: Yup.lazy((value) =>
        value === ''
          ? Yup.string()
          : Yup.string()
              .trim()
              .nullable()
              .matches('^[0-9]+$', 'Only numerical values are allowed.')
              .min(5, 'Min length needed is 5.')
              .max(15, 'Max length allowed is 15.')
      )
    })),
    aciAirManifestQtyList: Yup.array(Yup.object({
      quantity: Yup.string()
        .trim()
        .required(requiredMessage)
        .max(7, 'Max length allowed is 7.')
        .matches(/^\d+$/, 'Only numbers are allowed!'),
      uom: Yup.string()
        .required(requiredMessage)
        .max(3, 'Max length allowed is 3.')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric values are allowed.')
        .nullable()
    })),
    aciAirEquipmentList: Yup.array(Yup.object({
      containerid: Yup.string()
        .trim()
        .required(requiredMessage)
        .min(9, 'Minimum length needed is 9.')
        .max(10, 'Max length allowed is 10.')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric values are allowed.')
    })),
    aciAirItemList: Yup.array(),
    aciAirStatusNotifyList: Yup.array(Yup.object({
      statusType: Yup.string()
        .required(requiredMessage)
        .min(1, 'Min length needed is 1.')
        .max(4, 'Max length allowed is 4.')
        .matches(/^\d+$/, 'Only numbers are allowed!')
        .nullable(),
      email: Yup.string()
        .trim()
        .email('Please enter a valid email address!')
        .required(requiredMessage)
        .max(150, 'Max length allowed is 150.'),
      phoneNumber: Yup.lazy((value) =>
        value === ''
          ? Yup.string()
          : Yup.string()
              .trim()
              .max(15, 'Max length allowed is 15.')
              .matches(/^\d+$/, 'Only numbers are allowed!')
              .nullable()
      )
    }))
  })
}

export default makeValidationSchema
