import React, { useContext, useEffect } from 'react'
import { constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'
import { LookupsContext } from '../../../contexts/LookupsContext'

const {
  filter: { EQUAL }
} = constants

const FlightMatrixLookupsContext = React.createContext()

const FlightMatrixLookupsConsumer = FlightMatrixLookupsContext.Consumer

function responseToLookup(
  data,
  valueAccessor = 'code',
  labelAccessor = 'descriptionEn'
) {
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr[valueAccessor]]: curr[labelAccessor] }),
    {}
  )
}

const FlightMatrixLookupsProvider = ({ children }) => {
  const { initialize } = useContext(LookupsContext)
  const { fetchRecords } = useServices()

  useEffect(() => {
    const promises = [
      {
        key: 'customsOffice',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'CustomsOffice' },
          transform: (res) => responseToLookup(res.content)
        }
      },
      {
        key: 'sublocation',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'Sublocation' },
          transform: (res) => responseToLookup(res.content, 'intlcode')
        }
      },
      {
        key: 'airport',
        request: {
          fetch: fetchRecords,
          url: NaCodeMaintenanceApiUrls.GET,
          filters: [{ field: 'active', operator: EQUAL, value: true }],
          customData: { codeMType: 'AirPortCode' },
          transform: (res) => responseToLookup(res.content)
        }
      }
    ]

    initialize(promises)
  }, [])

  return (
    <FlightMatrixLookupsContext.Provider>
      {children}
    </FlightMatrixLookupsContext.Provider>
  )
}

export default FlightMatrixLookupsProvider

export { FlightMatrixLookupsContext, FlightMatrixLookupsConsumer }
