import React, { useContext } from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import AciAirKeys from '../../../../constants/locale/key/AciAir'
import { useTranslation } from 'cng-web-lib'
import { Divider, Grid, Typography } from '@material-ui/core'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import { LookupsContext } from '../../../../contexts/LookupsContext'

function ItemViewContent(props) {
  const { item } = props

  const { getLookupValue } = useContext(LookupsContext)

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const itemNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.ITEM_NO
    )
    const ladingQuantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_QUANTITY
    )
    const ladingUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_UOM
    )
    const productDesc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC
    )
    const productDesc1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC1
    )
    const productDesc2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC2
    )
    const productDesc3 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC3
    )
    const productDesc4 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC4
    )
    const productDesc5 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC5
    )
    const productDesc6 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC6
    )
    const productDesc7 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC7
    )
    const productDesc8 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC8
    )
    const grossWeightUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT_UOM
    )
    const grossWeight = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT
    )
    const measurementUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MEASUREMENT_UOM
    )
    const measurementVol = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MEASUREMENT_VOL
    )
    const undgCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.UNDG_CODE
    )
    const markNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MARK_NO
    )
    const hsCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.HS_CODE
    )

    return {
      itemNo,
      ladingQuantity,
      ladingUom,
      productDesc,
      productDesc1,
      productDesc2,
      productDesc3,
      productDesc4,
      productDesc5,
      productDesc6,
      productDesc7,
      productDesc8,
      grossWeightUom,
      grossWeight,
      measurementUom,
      measurementVol,
      undgCode,
      markNo,
      hsCode
    }
  }

  if (!item) return null

  return (
    <CngSection title='Classification & Weight'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.itemNo}>
                {item.itemNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <CngField label={translatedTextsObject.productDesc}>
                {item.productDesc}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc1}>
                {item.productDesc1}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc2}>
                {item.productDesc2}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc3}>
                {item.productDesc3}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc4}>
                {item.productDesc4}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc5}>
                {item.productDesc5}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc6}>
                {item.productDesc6}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc7}>
                {item.productDesc7}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.productDesc8}>
                {item.productDesc8}
              </CngField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.ladingQuantity}>
                {item.ladingQuantity}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.ladingUom}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('uomType', item.ladingUom)}
                  <TinyChip label={item.ladingUom} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.grossWeight}>
                {item.grossWeight}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.grossWeightUom}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('grossWeightUom', item.grossWeightUom)}
                  <TinyChip label={item.grossWeightUom} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.measurementVol}>
                {item.measurementVol}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.measurementUom}>
                {item.measurementUom && (
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('measurementUom', item.measurementUom)}
                    <TinyChip label={item.measurementUom} variant='outlined' />
                  </Typography>
                )}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.hsCode}>
                {item.hsCode && (
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('hsCode', item.hsCode)}
                    <TinyChip label={item.hsCode} variant='outlined' />
                  </Typography>
                )}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.undgCode}>
                {item.undgCode && (
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('undgCode', item.undgCode)}
                    <TinyChip label={item.undgCode} variant='outlined' />
                  </Typography>
                )}
              </CngField>
            </Grid>
            <Grid item xs={12}>
              <CngField label={translatedTextsObject.markNo}>
                {item.markNo}
              </CngField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CngSection>
  )
}

export default ItemViewContent
