import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FlightMatrixKeys from 'src/constants/locale/key/FlightMatrix'
import { Grid, Typography } from '@material-ui/core'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import {
  NaCustomsOfficeAutocompleteField,
  NaSublocationAutocompleteField,
  NaAirPortAutocompleteField
} from 'src/components/na'
import { useFormContext } from 'react-hook-form'
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  flightNo: '',
  origin: '',
  dest: '',
  portOfReport: '',
  wareHouseName: '',
  portOfLoading: '',
  deliveryCity: '',
  deliveryTerm: '',
  customsOffice: '',
  customsOfficeTerm: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.FLIGHT_MATRIX)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue } = useFormContext()
  console.log(getValues())

  function makeTranslatedTextsObject() {
    let flightMatrix = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.TITLE
    )
    let flightNo = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.FLIGHT_NO
    )
    let origin = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.ORIGIN)
    let dest = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.DEST)
    let portOfReport = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.PORT_OF_REPORT
    )
    let wareHouseName = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.WARE_HOUSE_NAME
    )
    let portOfLoading = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.PORT_OF_LOADING
    )
    let deliveryCity = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.DELIVERY_CITY
    )
    let deliveryTerm = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.DELIVERY_TERM
    )
    let customsOffice = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.CUSTOMS_OFFICE
    )
    let customsOfficeTerm = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.CUSTOMS_OFFICE_TERM
    )

    return {
      flightMatrix,
      flightNo,
      origin,
      dest,
      portOfReport,
      wareHouseName,
      portOfLoading,
      deliveryCity,
      deliveryTerm,
      customsOffice,
      customsOfficeTerm
    }
  }

  return (
    <CngSection
      title={translatedTextsObject.flightMatrix}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.flightNo}>
          <CngTextField
            name='flightNo'
            label={translatedTextsObject.flightNo}
            isRequired
            inputProps={{ maxLength: 10 }}
            onBlur={(e) => setValue('flightNo', e.target.value.toUpperCase())}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.origin}>
          <NaAirPortAutocompleteField
            name='origin'
            label={translatedTextsObject.origin}
            isRequired
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.dest}>
          <NaAirPortAutocompleteField
            name='dest'
            label={translatedTextsObject.dest}
            isRequired
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfReport}>
          <NaCustomsOfficeAutocompleteField
            name='portOfReport'
            label={translatedTextsObject.portOfReport}
            isRequired
            inputProps={{ maxLength: 4 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.wareHouseName}>
          <NaSublocationAutocompleteField
            name='wareHouseName'
            label={translatedTextsObject.wareHouseName}
            isRequired
            inputProps={{ maxLength: 4 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
          <NaAirPortAutocompleteField
            name='portOfLoading'
            label={translatedTextsObject.portOfLoading}
            isRequired
            inputProps={{ maxLength: 3 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deliveryCity}>
          <NaAirPortAutocompleteField
            name='deliveryCity'
            label={translatedTextsObject.deliveryCity}
            isRequired
            inputProps={{ maxLength: 3 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deliveryTerm}>
          <CngTextField
            name='deliveryTerm'
            label={translatedTextsObject.deliveryTerm}
            isRequired
            inputProps={{ maxLength: 25 }}
            onBlur={(e) => setValue('deliveryTerm', e.target.value.toUpperCase())}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffice}>
          <NaCustomsOfficeAutocompleteField
            name='customsOffice'
            label={translatedTextsObject.customsOffice}
            isRequired
            inputProps={{ maxLength: 4 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOfficeTerm}>
          <NaSublocationAutocompleteField
            name='customsOfficeTerm'
            label={translatedTextsObject.customsOfficeTerm}
            isRequired
            inputProps={{ maxLength: 4 }}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
