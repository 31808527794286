import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import FormProperties from '../aciaircommon/AciAirPartyListFormProperties'
import Alert from '../../../components/Alert'
import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function PartyListSection(props) {
  const { formSettings } = props

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const aciAirPartyList = watch('aciAirPartyList')
  const generatedIndexKey = useRef(aciAirPartyList.length)

  function makeTranslatedTextsObject() {
    const aciAirPartyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.TITLE
    )

    return { aciAirPartyList }
  }

  function handleAddParty(data) {
    const party = { ...data, _id: generatedIndexKey.current++ }
    const aciAirPartyList = [...getValues('aciAirPartyList'), party]

    setValue('aciAirPartyList', aciAirPartyList)
  }

  function handleDeleteParty(index) {
    const aciAirPartyList = [...getValues('aciAirPartyList')]

    aciAirPartyList.splice(index, 1)

    setValue('aciAirPartyList', aciAirPartyList)
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' style={{ fontWeight: 600 }}>
                  {translatedTextsObject.aciAirPartyList}
                </Typography>
                <Typography color='textSecondary' variant='caption'>
                  <Typography variant='inherit' color='error'>*</Typography>
                  Mandatory fields
                </Typography>
              </Grid>
              <Grid container justify='flex-end' item xs={12} md={6}>
                <Grid item xs='auto'>
                  <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}>
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      Please include the Shipper, and Consignee information on this form.
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {_.isEmpty(aciAirPartyList) ? (
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => handleAddParty(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              ) : (
                aciAirPartyList.map((party, index) => (
                  <Grid key={party.id || party._id} item xs={12}>
                    <Card variant='outlined'>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormProperties.Fields index={index} shouldHideMap={{ hideOptionalFields: formSettings.hideOptionalFields }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Add'>
                                  <span>
                                    <CngIconButton
                                      icon={['fal', 'plus']}
                                      onClick={() => handleAddParty(initialValues)}
                                      size='small'
                                      type='outlined'
                                    />
                                  </span>
                                </Tooltip>
                              </Grid>
                              <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Clone'>
                                  <span>
                                    <CngIconButton
                                      icon={['fal', 'copy']}
                                      onClick={() => handleAddParty({ ...party, id: undefined })}
                                      size='small'
                                      type='outlined'
                                    />
                                  </span>
                                </Tooltip>
                              </Grid>
                              <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Delete'>
                                  <span>
                                    <CngIconButton
                                      icon={['fal', 'trash']}
                                      onClick={() => handleDeleteParty(index)}
                                      size='small'
                                      type='outlined'
                                    />
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default React.memo(PartyListSection)
