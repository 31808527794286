import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import ItemViewContent from './view/ItemViewContent'
import { LookupsContext } from '../../../contexts/LookupsContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import DialogForm from '../../../components/aciacehighway/DialogForm'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import FormProperties from '../aciaircommon/AciAirItemListFormProperties'
import { Box, Card, Typography, useTheme } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngButton },
  CngDialog
} = components

function ItemSection() {
  const [confirmDialog, setConfirmDialog] = useState({ open: false, items: [] })
  const [formDialog, setFormDialog] = useState({ open: false, item: null })
  const [viewDialog, setViewDialog] = useState({ open: false, item: null })
  const { getLookupValue } = useContext(LookupsContext)

  const { getValues, setValue, watch } = useFormContext()
  const aciAirItemList = watch('aciAirItemList')
  const theme = useTheme()

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const aciAirItemList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.TITLE
    )
    const itemNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.ITEM_NO
    )
    const ladingQuantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_QUANTITY
    )
    const ladingUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_UOM
    )
    const productDesc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC
    )
    const grossWeight = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT_UOM
    )

    return {
      aciAirItemList,
      itemNo,
      ladingQuantity,
      ladingUom,
      productDesc,
      grossWeight,
      grossWeightUom
    }
  }

  const columns = [
    {
      field: 'itemNo',
      sortKey: 'itemNo',
      title: translatedTextsObject.itemNo
    },
    {
      field: 'productDesc',
      sortKey: 'productDesc',
      title: translatedTextsObject.productDesc
    },
    {
      field: 'ladingQuantity',
      sortKey: 'ladingQuantity',
      title: translatedTextsObject.ladingQuantity
    },
    {
      field: 'ladingUom',
      sortKey: 'ladingUom',
      title: translatedTextsObject.ladingUom,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('uomType', rowData.ladingUom)}
          <TinyChip label={rowData.ladingUom} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'grossWeight',
      sortKey: 'grossWeight',
      title: translatedTextsObject.grossWeight
    },
    {
      field: 'grossWeightUom',
      sortKey: 'grossWeightUom',
      title: translatedTextsObject.grossWeightUom,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('grossWeightUom', rowData.grossWeightUom)}
          <TinyChip label={rowData.grossWeightUom} variant='outlined' />
        </Typography>
      )
    }
  ]

  function handleAddItem(data) {
    const aciAirItemList = [...getValues('aciAirItemList'), data]

    setValue('aciAirItemList', aciAirItemList)
    setFormDialog({ open: false, item: null })
  }

  function handleDeleteItem() {
    if (_.isEmpty(confirmDialog.items)) return

    const _ids = confirmDialog.items.map((item) => item._id)
    const aciAirItemList = [...getValues('aciAirItemList')].filter(
      (item) => !_ids.includes(item._id)
    )

    setValue('aciAirItemList', aciAirItemList)
    setConfirmDialog({ open: false, items: [] })
  }

  function handleEditItem(data) {
    const aciAirItemList = [...getValues('aciAirItemList')]
    const index = aciAirItemList.findIndex((item) => item._id === data._id)

    aciAirItemList[index] = data

    setValue('aciAirItemList', aciAirItemList)
    setFormDialog({ open: false, item: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          header={
            <Typography variant='h5' style={{ fontWeight: 600 }}>
              {translatedTextsObject.aciAirItemList}
            </Typography>
          }
          columns={columns}
          data={aciAirItemList}
          actions={[
            {
              disabled: aciAirItemList.length >= 998,
              icon: ['fal', 'plus-circle'],
              label: 'Add commodity',
              onClick: () => setFormDialog({ open: true, item: null }),
              tooltip:
                aciAirItemList.length >= 998 ? 'Only 998 records allowed' : null
            }
          ]}
          customRowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setFormDialog({ open: true, item: rowData }),
              tooltip: 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: (rowData) =>
                setConfirmDialog({ open: true, items: [rowData] }),
              tooltip: 'Delete'
            }
          ]}
          onRowClick={(rowData) => setViewDialog({ open: true, item: rowData })}
          select
          selectActions={[
            {
              icon: ['fal', 'trash'],
              tooltip: 'Delete',
              label: 'Delete',
              onClick: (selectedRows) =>
                setConfirmDialog({ open: true, items: selectedRows }),
              buttonProps: {
                style: {
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main
                }
              }
            }
          ]}
        />
      </Card>
      <DialogForm
        disableBackdropClick
        formProperties={FormProperties}
        fullWidth
        maxWidth='lg'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, item: null })}
        onSubmit={formDialog.item ? handleEditItem : handleAddItem}
        title={`${formDialog.item ? 'Edit' : 'Add'} commodity`}
        value={formDialog.item}
      />
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <ItemViewContent item={viewDialog.item} />
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setViewDialog({ open: false, item: null })}>
            OK
          </CngButton>
        }
        dialogTitle='View commodity'
        maxWidth='md'
        onClose={() => setViewDialog({ open: false, item: null })}
        open={viewDialog.open}
        shouldShowCloseButton
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, items: [] })}
        onCancel={() => setConfirmDialog({ open: false, items: [] })}
        onConfirm={handleDeleteItem}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ItemSection
