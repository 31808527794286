import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ConveyanceKeys from '../../../constants/locale/key/Conveyance'
import Alert from '../../../components/Alert'
import FormProperties from './ConveyanceRouteDTOsFormProperties'
import { Box, Card, Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function RoutesSection() {
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const conveyanceRouteDTOs = watch('conveyanceRouteDTOs')
  const generatedIndexKey = useRef(conveyanceRouteDTOs.length)
  const isMaxAmountReached = conveyanceRouteDTOs.length >= 9
  const maxAmountMessage = 'Only 9 records allowed'

  function makeTranslatedTextsObject() {
    const conveyanceRouteDTOs = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceRouteDTOs.TITLE
    )

    return { conveyanceRouteDTOs }
  }

  function handleAddRoute(data) {
    const route = { ...data, _id: generatedIndexKey.current++ }
    const conveyanceRouteDTOs = [...getValues('conveyanceRouteDTOs'), route]

    setValue('conveyanceRouteDTOs', conveyanceRouteDTOs)
  }

  function handleDeleteRoute(index) {
    const conveyanceRouteDTOs = [...getValues('conveyanceRouteDTOs')]

    conveyanceRouteDTOs.splice(index, 1)

    setValue('conveyanceRouteDTOs', conveyanceRouteDTOs)
  }

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' style={{ fontWeight: 600 }}>
                  {translatedTextsObject.conveyanceRouteDTOs}
                </Typography>
                <Typography color='textSecondary' variant='caption'>
                  <Typography variant='inherit' color='error'>*</Typography>
                  Mandatory fields
                </Typography>
              </Grid>
              <Grid container justify='flex-end' item xs={12} md={6}>
                <Grid item xs='auto'>
                  <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}>
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      Provide all itinerary locations on route between Airport of Departure and First Port of Arrival in chronological order.
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {_.isEmpty(conveyanceRouteDTOs) ? (
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                    <span>
                      <CngIconButton
                        disabled={isMaxAmountReached}
                        icon={['fal', 'plus']}
                        onClick={() => handleAddRoute(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              ) : (
                conveyanceRouteDTOs.map((route, index) => (
                  <React.Fragment key={route.id || route._id}>
                    <Grid item xs={12}>
                      <Grid alignItems='center' container spacing={2}>
                        <Grid item xs={12} md>
                          <FormProperties.Fields index={index} />
                        </Grid>
                        <Grid item xs={12} md='auto'>
                          <Grid container spacing={1}>
                            <Grid item xs='auto'>
                              <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                                <span>
                                  <CngIconButton
                                    disabled={isMaxAmountReached}
                                    icon={['fal', 'plus']}
                                    onClick={() => handleAddRoute(initialValues)}
                                    size='small'
                                    type='outlined'
                                  />
                                </span>
                              </Tooltip>
                            </Grid>
                            <Grid item xs='auto'>
                              <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Clone'}>
                                <span>
                                  <CngIconButton
                                    disabled={isMaxAmountReached}
                                    icon={['fal', 'copy']}
                                    onClick={() => handleAddRoute({ ...route, id: undefined })}
                                    size='small'
                                    type='outlined'
                                  />
                                </span>
                              </Tooltip>
                            </Grid>
                            <Grid item xs='auto'>
                              <Tooltip placement='bottom' title='Delete'>
                                <span>
                                  <CngIconButton
                                    icon={['fal', 'trash']}
                                    onClick={() => handleDeleteRoute(index)}
                                    size='small'
                                    type='outlined'
                                  />
                                </span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {conveyanceRouteDTOs.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default React.memo(RoutesSection)
