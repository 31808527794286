import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    email: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(250, 'Max length allowed is 300 ')
      .min(5, 'Min length needed is 5')
      .matches('^[A-Za-z0-9+_.-]+@(.+)$', 'Please enter valid email id'),
    phoneNumber: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .nullable()
            .matches('^[0-9]+$', 'Please enter valid phone number')
            .min(5, 'Min length needed is 5')
            .max(15, 'Max length allowed is 15')
    ),
    statusType: Yup.string().required(requiredMessage).nullable()
  })
}

export default makeValidationSchema
