import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ConveyanceKeys from '../../../constants/locale/key/Conveyance'
import CngSection from '../../../components/cngcomponents/CngSection'
import FormProperties from './ConveyanceStatusNotifyListFormProperties'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function StatusNotificationsSection() {
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()

  const conveyanceStatusNotifyList = watch('conveyanceStatusNotifyList')
  const generatedIndexKey = useRef(conveyanceStatusNotifyList.length)
  const isMaxAmountReached = conveyanceStatusNotifyList.length >= 5
  const maxAmountMessage = 'Only 5 records allowed'

  function makeTranslatedTextsObject() {
    const conveyanceStatusNotifyList = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.TITLE
    )

    return { conveyanceStatusNotifyList }
  }

  function handleAddStatusNotification(data) {
    const statusNotification = { ...data, _id: generatedIndexKey.current++ }
    const conveyanceStatusNotifyList = [
      ...getValues('conveyanceStatusNotifyList'),
      statusNotification
    ]

    setValue('conveyanceStatusNotifyList', conveyanceStatusNotifyList)
  }

  function handleDeleteStatusNotification(index) {
    const conveyanceStatusNotifyList = [
      ...getValues('conveyanceStatusNotifyList')
    ]

    conveyanceStatusNotifyList.splice(index, 1)

    setValue('conveyanceStatusNotifyList', conveyanceStatusNotifyList)
  }

  return (
    <CngSection
      title={translatedTextsObject.conveyanceStatusNotifyList}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        {_.isEmpty(conveyanceStatusNotifyList) ? (
          <Grid item xs='auto'>
            <Tooltip
              placement='bottom'
              title={isMaxAmountReached ? maxAmountMessage : 'Add'}
            >
              <span>
                <CngIconButton
                  disabled={isMaxAmountReached}
                  icon={['fal', 'plus']}
                  onClick={() => handleAddStatusNotification(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        ) : (
          conveyanceStatusNotifyList.map((statusNotification, index) => (
            <React.Fragment
              key={statusNotification.id || statusNotification._id}
            >
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md>
                    <FormProperties.Fields index={index} />
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing={1}>
                      <Grid item xs='auto'>
                        <Tooltip
                          placement='bottom'
                          title={isMaxAmountReached ? maxAmountMessage : 'Add'}
                        >
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'plus']}
                              onClick={() =>
                                handleAddStatusNotification(initialValues)
                              }
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip
                          placement='bottom'
                          title={
                            isMaxAmountReached ? maxAmountMessage : 'Clone'
                          }
                        >
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'copy']}
                              onClick={() =>
                                handleAddStatusNotification({
                                  ...statusNotification,
                                  id: undefined
                                })
                              }
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title='Delete'>
                          <span>
                            <CngIconButton
                              icon={['fal', 'trash']}
                              onClick={() =>
                                handleDeleteStatusNotification(index)
                              }
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {conveyanceStatusNotifyList.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default React.memo(StatusNotificationsSection)
