import { constants, useServices, useTranslation } from 'cng-web-lib'

import FlightMatrixApiUrls from 'src/apiUrls/FlightMatrixApiUrls'
import React, { useContext, useRef, useState } from 'react'
import { generatePath } from 'react-router-dom'
import FlightMatrixKeys from 'src/constants/locale/key/FlightMatrix'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from '../../../paths/pathMap'
import { LookupsContext } from '../../../contexts/LookupsContext'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'

const {
  filter: { LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const { getLookupValue } = useContext(LookupsContext)
  const { deleteRecord } = useServices()
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    matrix: null
  })

  const { translate } = useTranslation(Namespace.FLIGHT_MATRIX)
  const translatedTextsObject = makeTranslatedTextsObject()
  const tableRef = useRef()

  function makeTranslatedTextsObject() {
    let flightNo = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.FLIGHT_NO
    )
    let origin = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.ORIGIN)
    let dest = translate(Namespace.FLIGHT_MATRIX, FlightMatrixKeys.DEST)
    let portOfReport = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.PORT_OF_REPORT
    )
    let wareHouseName = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.WARE_HOUSE_NAME
    )
    let customsOffice = translate(
      Namespace.FLIGHT_MATRIX,
      FlightMatrixKeys.CUSTOMS_OFFICE
    )

    return {
      flightNo,
      origin,
      dest,
      portOfReport,
      wareHouseName,
      customsOffice
    }
  }

  const columns = [
    {
      field: 'flightNo',
      sortKey: 'flightNo',
      title: translatedTextsObject.flightNo
    },
    {
      field: 'origin',
      sortKey: 'origin',
      title: translatedTextsObject.origin,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('airport', rowData.origin)}
          <TinyChip label={rowData.origin} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'dest',
      sortKey: 'dest',
      title: translatedTextsObject.dest,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('airport', rowData.dest)}
          <TinyChip label={rowData.dest} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'portOfReport',
      sortKey: 'portOfReport',
      title: translatedTextsObject.portOfReport,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('customsOffice', rowData.portOfReport)}
          <TinyChip label={rowData.portOfReport} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'wareHouseName',
      sortKey: 'wareHouseName',
      title: translatedTextsObject.wareHouseName,
      render: (rowData) => getLookupValue('sublocation', rowData.wareHouseName),
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('sublocation', rowData.wareHouseName)}
          <TinyChip label={rowData.wareHouseName} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'customsOffice',
      sortKey: 'customsOffice',
      title: translatedTextsObject.customsOffice,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('customsOffice', rowData.customsOffice)}
          <TinyChip label={rowData.customsOffice} variant='outlined' />
        </Typography>
      )
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.flightNo,
      type: 'textfield',
      name: 'flightNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.origin,
      type: 'textfield',
      name: 'origin',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.dest,
      type: 'textfield',
      name: 'dest',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.portOfReport,
      type: 'textfield',
      name: 'portOfReport',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.wareHouseName,
      type: 'textfield',
      name: 'wareHouseName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customsOffice,
      type: 'textfield',
      name: 'customsOffice',
      operatorType: LIKE
    }
  ]

  function handleDeleteMatrix() {
    if (!confirmDialog.matrix) return

    deleteRecord.execute(
      FlightMatrixApiUrls.DELETE,
      confirmDialog.matrix,
      () => {
        showNotification('success', 'Delete succeeded')

        if (tableRef.current?.performRefresh) {
          tableRef.current.performRefresh()
        }
      },
      (error) => console.error(error),
      () => setConfirmDialog({ open: false, matrix: null })
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.FLIGHT_MATRIX_ADD_VIEW)
            },
            label: 'Create Flight Matrix'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: FlightMatrixApiUrls.EXPORT }}
        fetch={{ url: FlightMatrixApiUrls.GET }}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(
            generatePath(pathMap.FLIGHT_MATRIX_DETAILS_VIEW, { id: rowData.id })
          )
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.FLIGHT_MATRIX_EDIT_VIEW, {
                  id: rowData.id
                })
              )
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, matrix: rowData })
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, matrix: null })}
        onCancel={() => setConfirmDialog({ open: false, matrix: null })}
        onConfirm={handleDeleteMatrix}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
