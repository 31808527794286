import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const today = new Date()

  return Yup.object({
    messageType: Yup.string(),
    formType: Yup.number().required(requiredMessage).nullable(),
    conveyanceRefNo: Yup.string()
      .trim()
      .max(16, 'Max length allowed is 16.')
      .required(requiredMessage)
      .matches(
        '[a-zA-Z0-9-.s]$',
        'Conveyance Reference Number cannot contain special characters.'
      ),
    carrierCode: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(4, 'Should be a valid 4 character Carrier Code')
      .max(4, 'Should be a valid 4 character Carrier Code')
      .matches(
        '^[a-zA-Z0-9]{3}[-]{1}$',
        'Carrier Code must be 4 characters long, with 3 alphanumeric followed by dash.'
      ),
    flightNo: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(10, 'Max length allowed is 10.')
      .min(2, 'Min length required is 02.')
      .matches(
        '[a-zA-Z0-9-.s]$',
        'Flight number should only be alpha numeric.'
      ),
    airCraftType: Yup.string().required(requiredMessage).nullable(),
    airCraftRegNO: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(12, 'Max length allowed is 12.')
      .matches(
        '[a-zA-Z0-9-.s]$',
        'Aircraft register number should only be alpha numeric.'
      ),
    departureDateAndTime: Yup.string()
      .required(requiredMessage)
      .test(
        'departureDateAndTime',
        'Please select a valid future Departure Date Time in yyyy-mm-dd HH:mm format.',
        (value) => {
          return moment(value).isValid()
        }
      )
      .nullable(),
    departurePort: Yup.string().required(requiredMessage).nullable(),
    arrivalDateAndTime: Yup.string()
      .required(requiredMessage)
      .test(
        'arrivalDateAndTime',
        'Please select a valid future Arrival Date Time in yyyy-mm-dd HH:mm format.',
        (value) => {
          return moment(value).isValid()
        }
      )
      .nullable(),
    arrivalPort: Yup.string().required(requiredMessage).nullable(),
    arrivalTerminal: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .matches(
              '[a-zA-Z0-9-.s]$',
              'Terminal should only be alpha numeric.'
            )
            .max(30, 'Max length allowed is 30.')
            .nullable()
    ),
    reportType: Yup.number().required(requiredMessage).nullable(),
    scheduledSubmissionFlag: Yup.boolean().nullable(),
    scheduleDateAndTime: Yup.string()
      .when('scheduledSubmissionFlag', {
        is: false,
        then: (schema) => schema,
        otherwise: Yup.string().when('scheduledSubmissionFlag', {
          is: true,
          then: Yup.string()
            .required('Please select a valid date.')
            .test(
              'scheduleDateAndTime',
              'Please select a valid Future Scheduled Date Time in yyyy-mm-dd HH:mm:ss format.',
              (value) => {
                var formattedDate = moment(value).format('YYYY-MM-DD HH:mm:ss')
                var formattedToday = moment(today).format('YYYY-MM-DD HH:mm:ss')
                return moment(formattedDate).isAfter(formattedToday, 'minutes')
              }
            )
        })
      })
      .nullable(),
    templateFlag: Yup.boolean().required(requiredMessage),
    templateName: Yup.string()
      .when('templateFlag', {
        is: true,
        then: Yup.string()
          .trim()
          .max(1, 'Max length allowed is 1')
          .max(50, 'Max length is 50')
          .required(requiredMessage)
          .matches('[a-zA-Z0-9s]$', 'Only alphanumeric characters are allowed.')
      })
      .nullable(),
    hdrStatus: Yup.string().nullable(),
    messageFun: 
    Yup.string()
      .when('hdrStatus', {
        is: (value) => value === '1000',
        then: Yup.string().test(
          'messageFun',
          'Confirmed record cannot be submitted as Original.',
          function (value) {
            return value !== "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '20' || value === '21' || value === '28'),
        then: Yup.string().test(
          'messageFun',
          'Error status record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '29'),
        then: Yup.string().test(
          'messageFun',
          'Error status record should be submitted as Original / Change.',
          function (value) {
            return (value == "9" || value == "4")
          }
        )
      })      
      .when('hdrStatus', {
        is: (value) => (value === '22'),
        then: Yup.string().test(
          'messageFun',
          'Conformance/Syntax Error status record should be submitted as Change.',
          function (value) {
            return value === "4"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '1003' || value === '1004'),
        then: Yup.string().test(
          'messageFun',
          'Cancel Accepted/Rejected record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .nullable()
      .required(requiredMessage),
    conveyanceRouteDTOs: Yup.array(),
    conveyanceStatusNotifyList: Yup.array()
  })
}

export default makeValidationSchema
