import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    flightNo: Yup.string()
      .required(requiredMessage)
      .matches(
        /^[a-zA-Z0-9]+$/,
        'Please enter alphanumeric value with out space'
      )
      .nullable(),
    origin: Yup.string()
      .required(requiredMessage)
      .matches(/^[a-zA-Z]+$/, 'Please enter aplhabetical value')
      .nullable(),
    dest: Yup.string()
      .required(requiredMessage)
      .matches(/^[a-zA-Z]+$/, 'Please enter aplhabetical value')
      .nullable(),
    portOfReport: Yup.string()
      .required(requiredMessage)
      .matches(/^[0-9]+$/, 'Please enter numeric values')
      .nullable(),
    wareHouseName: Yup.string()
      .required(requiredMessage)
      .matches(/^[0-9]+$/, 'Please enter numeric values')
      .nullable(),
    portOfLoading: Yup.string()
      .required(requiredMessage)
      .matches(/^[a-zA-Z]+$/, 'Please enter aplhabetical value')
      .nullable(),
    deliveryCity: Yup.string()
      .required(requiredMessage)
      .matches(/^[a-zA-Z]+$/, 'Please enter aplhabetical value')
      .nullable(),
    deliveryTerm: Yup.string()
      .required(requiredMessage)
      .matches(/^[a-zA-Z0-9\-\.\s]+$/, 'Cannot contain special charaters')
      .nullable(),
    customsOffice: Yup.string()
      .required(requiredMessage)
      .matches(/^[0-9]+$/, 'Please enter numeric values')
      .nullable(),
    customsOfficeTerm: Yup.string()
      .required(requiredMessage)
      .matches(/^[0-9]+$/, 'Please enter numeric values')
      .nullable()
  })
}

export default makeValidationSchema
