import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    locationCode: Yup.string().required(requiredMessage).nullable()
  })
}

export default makeValidationSchema
