import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import ConveyanceKeys from '../../../../constants/locale/key/Conveyance'
import { LookupsContext } from '../../../../contexts/LookupsContext'
import StatusBar from '../../../../components/aciacehighway/StatusBar'
import CngField from '../../../../components/cngcomponents/CngField'
import CngSection from '../../../../components/cngcomponents/CngSection'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import _ from 'lodash'
import ViewLogsHistory from '../../aciaircommon/ViewLogsHistory'

function ReportViewContent(props) {
  const { data, reportId, showNotification } = props

  const { getLookupValue } = useContext(LookupsContext)
  const { translate } = useTranslation(Namespace.CONVEYANCE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const carrierCode = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CARRIER_CODE
    )
    const conveyanceRefNo = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.CONVEYANCE_REF_NO
    )
    const flightNo = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.FLIGHT_NO
    )
    const departurePort = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.DEPARTURE_PORT
    )
    const departureDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.DEPARTURE_DATE_AND_TIME
    )
    const arrivalPort = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_PORT
    )
    const arrivalDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_DATE_AND_TIME
    )
    const airCraftType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.AIR_CRAFT_TYPE
    )
    const airCraftRegNO = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.AIR_CRAFT_REG_N_O
    )
    const arrivalTerminal = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ARRIVAL_TERMINAL
    )
    const scheduleDateAndTime = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.SCHEDULE_DATE_AND_TIME
    )
    const conveyanceRouteDTOs = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.TITLE
    )
    const locationCode = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceRouteDTOs.LOCATION_CODE
    )
    const conveyanceStatusNotifyList = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.TITLE
    )
    const statusType = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.STATUS_TYPE
    )
    const email = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.EMAIL
    )
    const phoneNumber = translate(
      Namespace.CONVEYANCE,
      ConveyanceKeys.ConveyanceStatusNotifyList.PHONE_NUMBER
    )

    return {
      carrierCode,
      conveyanceRefNo,
      flightNo,
      departurePort,
      departureDateAndTime,
      arrivalPort,
      arrivalDateAndTime,
      airCraftType,
      airCraftRegNO,
      arrivalTerminal,
      scheduleDateAndTime,
      conveyanceRouteDTOs,
      locationCode,
      conveyanceStatusNotifyList,
      email,
      phoneNumber,
      statusType,
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatusBar status={data.hdrStatus} />
      </Grid>
      <Grid item xs={12}>
        <CngSection
          action={
            <Box display='flex' alignItems='center' style={{ gap: 8 }}>
              <FontAwesomeIcon
                color={
                  data.scheduledSubmissionFlag
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
                icon={['fal', data.scheduledSubmissionFlag ? 'circle-check' : 'ban']}
              />
              <Typography
                color='textSecondary'
                variant='body2'
                style={{ fontWeight: 600 }}
              >
                Schedule Delivery
              </Typography>
            </Box>
          }
          title='General Information'
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.carrierCode}>
                    {data.carrierCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.conveyanceRefNo}>
                    {data.conveyanceRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.flightNo}>
                    {data.flightNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.departurePort}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('airport', data.departurePort)}
                      <TinyChip label={data.departurePort} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.departureDateAndTime}>
                    {data.departureDateAndTime &&
                      moment(data.departureDateAndTime).format('DD/MM/YYYY HH:mm:ss')}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.arrivalPort}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('airport', data.arrivalPort)}
                      <TinyChip label={data.arrivalPort} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.arrivalDateAndTime}>
                    {data.arrivalDateAndTime &&
                      moment(data.arrivalDateAndTime).format('DD/MM/YYYY HH:mm:ss')}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.airCraftType}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('aircraft', data.airCraftType)}
                      <TinyChip label={data.airCraftType} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.airCraftRegNO}>
                    {data.airCraftRegNO}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.arrivalTerminal}>
                    {data.arrivalTerminal}
                  </CngField>
                </Grid>
                {data.scheduledSubmissionFlag &&
                  data.scheduleDateAndTime && (
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.scheduleDateAndTime}>
                        {moment(data.scheduleDateAndTime).format('DD/MM/YYYY HH:mm:ss')}
                      </CngField>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSection title={translatedTextsObject.conveyanceRouteDTOs}>
                <Grid container spacing={2}>
                  {data.conveyanceRouteDTOs.map((route) => (
                    <Grid key={route.id} item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.locationCode}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('airport', route.locationCode)}
                          <TinyChip label={route.locationCode} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                  ))}
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.conveyanceStatusNotifyList}>
          {!_.isEmpty(data.conveyanceStatusNotifyList) ? (
            <Grid container spacing={2}>
              {data.conveyanceStatusNotifyList.map((statusNotification, index) => (
                <React.Fragment key={statusNotification.id}>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.email}>
                        {statusNotification.email}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.phoneNumber}>
                        {statusNotification.phoneNumber}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.statusType}>
                        {getLookupValue('statusType', statusNotification.statusType)}
                      </CngField>
                    </Grid>
                  </Grid>
                  {data.conveyanceStatusNotifyList.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <ViewLogsHistory
          ccnValue={data.conveyanceRefNo}
          headerId={reportId}
          showNotification={showNotification}
        />
      </Grid>
    </Grid>
  )
}

export default ReportViewContent
